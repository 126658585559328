import { createStyles, Navbar, ScrollArea, Select, SimpleGrid, Input, Button, Center, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { FurnitureItem } from "./FurnitureItem";
import { useFurnitureStore } from "../../../stores/FurnitureStore";
import { GetInformationFloor } from "../../../editor/editor/actions/GetInformationFloor";
import { sendQuote } from "../../../api/api-client";
import { cleanNotifications, showNotification } from '@mantine/notifications';
import { GetPlanImage } from "../../../editor/editor/actions/GetPlanImage";
import { useStore } from "../../../stores/EditorStore";

const useStyles = createStyles((theme) => ({
    mb: {
        marginTop: theme.spacing.xs,
        marginBottom: theme.spacing.xs
    }
}));

export function Quote() {
    const [category, setCategory] = useState('');
    const { categories, currentFurnitureData, getCurrentFurnitureData } = useFurnitureStore();
    const [products, setProducts] = useState([]);
    const [finalData, setFinalData] = useState([]);
    let [total, setCount] = useState(0);

    const [email, setEmail] = useState('');
    const handleChange = event => {
        setEmail(event.target.value);
      };

    const node:any = new GetInformationFloor(); 
    let [labelButton, setLabelButton] = useState('Enviar cotización');
    
    const colorWarning = '#b2292e';
    const colorSuccess = '#339af0';
    const [emailMessage, setEmailMessage] = useState('');
    const failedMessages = {
        required: '* Campo requerido.',
        minLength: '* Al menos 3 caracteres',
        maxLength: '* No más de 100 caracteres.',
        isEmail: '* Correo electrónico no válido.',
    }

    const validateEmail = (event) => {
        if(event.target.value.length < 3){
          event.target.style.borderColor = colorWarning;
          setEmailMessage(failedMessages.minLength);
        }
        
        if(event.target.value.length > 100){
          event.target.style.borderColor = colorWarning;
          setEmailMessage(failedMessages.maxLength);
        }

        const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if(!re.test(event.target.value)){
            event.target.style.borderColor = colorWarning;
            setEmailMessage(failedMessages.isEmail);
        }

        if (event.target.value.length <= 100) {
          if(event.target.value.length >= 3 && re.test(event.target.value)){
            event.target.style.borderColor = colorSuccess;
            setEmailMessage('');
          }
          setEmail(event.target.value);
        }
      }

    useEffect(() => {
        if (category) {
            getCurrentFurnitureData(category)
 
        }
    }, [category])

    useEffect(() => {
        node.execute();
        total = node.receiver.totalPrices;
        setCount(total);

        const countsById = {};
        node.receiver.furnitures.forEach(f  => {
            countsById[f.data._id] = (countsById[f.data._id] || 0) + 1;
        });

        const finalArray = Object.entries(countsById)
        .map(([_id, count]) => ({ _id, count }))
        .sort((a:any, b:any) => b.count - a.count);

        let array = [];
        node.receiver.furnitures.forEach(f  => {
            f.data.quantity = finalArray[finalArray.findIndex(x => +x._id == +f.data._id)]?.count ? finalArray[finalArray.findIndex(x => +x._id == +f.data._id)].count : 0;
            if(!array.find(x=>x._id == f.data._id)){
                array.push(f.data);
            }
        });

        setFinalData(array);   
        setProducts(finalData.sort((a, b) => Number(b.isGondola) - Number(a.isGondola)).map((item) =>
        (
            <>
            <SimpleGrid style={{ padding: 3 }} cols={2}>
                <Text key={item._id} style={{ fontSize: 15, marginTop: 10 }}>{item.name}:</Text>
                <Text key={item._id} style={{ fontSize: 15, marginTop: 10, placeSelf:"end"}}>{finalArray[finalArray.findIndex(x => +x._id == +item._id)]?.count ? finalArray[finalArray.findIndex(x => +x._id == +item._id)].count : 0}x{currencyFormat(item.price)} </Text>
            </SimpleGrid>            
            </>            
        )
        ));
    }, [currentFurnitureData])

    useEffect(() => {
        if(categories && categories[0] && categories[0]._id) {
            setCategory(categories[0]._id)
        } else {
            showNotification({
                title: '',
                message: `Verifica tu conexión a internet.`,
                styles: (theme) => ({
                    root: { '&::before': { backgroundColor: '#b2292e' } },
                    title: { color: '#b2292e' },
                    description: { color: '#b2292e !important' }
                }),
            })
        }
    }, [categories])    

    return (<>
        <Navbar.Section style={{ width: "400", height:"100%"}}grow mx="-xs" px="xs">
            <ScrollArea style={{ width: "400", height:"90%"}}>
                <Center>
                    <Text style={{ fontSize: 20, fontWeight: "bold"}}>Información de la cotización</Text>
                </Center>
                <Center style={{ marginTop: 10 }}>
                    <small style={{fontWeight:"bold"}}>Precios expresados en MXN (pesos mexicanos).</small>
                </Center>
                {products}
                <SimpleGrid style={{ padding: 3, marginTop:10 }} cols={2}>
                    <Text style={{fontWeight: "bold", marginTop: 10}}>Subtotal:</Text>
                    <Text style={{fontWeight: "bold", marginTop: 10, placeSelf:"end"}}>{currencyFormat(total)}</Text>        
                </SimpleGrid>
                <SimpleGrid style={{ padding: 3 }} cols={2}>
                    <Text style={{fontWeight: "bold", marginTop: 10}}>IVA (16%):</Text>  
                    <Text style={{fontWeight: "bold", marginTop: 10, placeSelf:"end"}}>{currencyFormat((total/100)*16)}</Text>        
                </SimpleGrid>
                <SimpleGrid style={{ padding: 3 }} cols={2}>
                    <Text style={{fontWeight: "bold", marginTop: 10}}>Total:</Text>  
                    <Text style={{fontWeight: "bold", marginTop: 10, placeSelf:"end"}}>{currencyFormat(total + ((total/100)*16))}</Text>         
                </SimpleGrid> 

                <Text style={{ marginTop: 30 }}>Ingrese su correo electrónico</Text>
                <Input placeholder='Correo electrónico' onChange={validateEmail} value={email}></Input>
                <Text className='text-error'>{emailMessage}</Text>
                <Center style={{ marginTop: 10 }}>
                    <Button style={{ width: "100%", backgroundColor: "#b2292e" }} onClick={async () => 
                    {
                        if(!email){
                            cleanNotifications();
                            showNotification({
                                title: '',
                                message: 'Rellene la información correpondiente.',
                                styles: (theme) => ({
                                    root: { '&::before': { backgroundColor: '#b2292e' } },
                                    title: { color: '#b2292e' },
                                    description: { color: '#b2292e !important' }
                                }),
                            });
                            return;
                        }
                        
                        if(emailMessage != ''){
                            cleanNotifications();
                            showNotification({
                                title: '',
                                message: 'Revise las validaciones del formulario.',
                                styles: (theme) => ({
                                    root: { '&::before': { backgroundColor: '#b2292e' } },
                                    title: { color: '#b2292e' },
                                    description: { color: '#b2292e !important' }
                                }),
                            });
                            return;
                        }

                        if(total == 0){
                            cleanNotifications();
                            showNotification({
                                title: '',
                                message: 'Agregue al menos una góndola para enviar la cotización.',
                                styles: (theme) => ({
                                    root: { '&::before': { backgroundColor: '#b2292e' } },
                                    title: { color: '#b2292e' },
                                    description: { color: '#b2292e !important' }
                                }),
                            });
                            return;
                        }

                        let action:any = new GetPlanImage();
                        await action.execute();

                        let data = {
                            email: email,
                            data: finalData,
                            total:total.toFixed(2),
                            imageBase64: action.receiver.planImage.currentSrc
                        };

                        setLabelButton('Enviando...');
                        useStore.setState({loading:true});
                        let res = await sendQuote(data);
                        useStore.setState({loading:false});
                        cleanNotifications();
                        showNotification({
                            title: '',
                            message: 'Cotización enviada correctamente, Revise su correo para verificar la cotización.',
                            styles: (theme) => ({
                                root: { '&::before': { backgroundColor: '#4caf50' } },
                                title: { color: '#4caf50' },
                                description: { color: '#4caf50 !important'  }
                            }),
                        });
                        setLabelButton('Volver a enviar');
                        setEmail('');         
                    }}> 
                        {labelButton}
                    </Button>
                    
                </Center>
                <Text style={{textAlignLast:"center", marginTop:120, fontSize:20}}><a href="https://tammex.com/" target="_blank">Volver al sitio</a></Text>               
            </ScrollArea>

        </Navbar.Section>        
    </>)
}

function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
