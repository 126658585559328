import { createStyles, Navbar, ScrollArea, SimpleGrid, Center, Text, Tooltip, Image, Button, Group, Drawer } from "@mantine/core";
import { useEffect, useState } from "react";
import { FurnitureItem } from "./FurnitureItem";
import { useFurnitureStore } from "../../../stores/FurnitureStore";
import { showNotification, cleanNotifications } from "@mantine/notifications";
import { GetInformationFloor } from "../../../editor/editor/actions/GetInformationFloor";
import TammexLogo from '../../../res/logo.png'
import { AddFurnitureAction } from "../../../editor/editor/actions/AddFurnitureAction";
import { DeleteFurnitureAction } from "../../../editor/editor/actions/DeleteFurnitureAction";
import { TransformLayer } from "../../../editor/editor/objects/TransformControls/TransformLayer";
import { Minus, Help, Vector, Download, DeviceFloppy } from "tabler-icons-react";
import { NavbarLink } from "../../NavbarLink";
import { isMobile } from 'react-device-detect';
import { GetPlanImage } from "../../../editor/editor/actions/GetPlanImage";
import { useStore } from "../../../stores/EditorStore";
import { getQuoteDocument } from "../../../api/api-client";
import { Quote } from '../../FurnitureControls/FurnitureAddPanel/Quote';

const useStyles = createStyles((theme) => ({
    mb: {
        marginTop: theme.spacing.xs,
        marginBottom: theme.spacing.xs
    }
}));

interface FurnitureAddPanelProps {
    closeModal?(): void;
  }
  
export function FurnitureAddPanel() {
    const { classes } = useStyles();
    const [category, setCategory] = useState('');
    const { categories, currentFurnitureData, getCurrentFurnitureData } = useFurnitureStore();
    const [cards, setCards] = useState([]);
    const [cards2, setCards2] = useState([]); 
    const [products, setProducts] = useState([]);
    const [showInstructions, setShowInstructions] = useState(false);
    const [textButtonInstructions, setTextButtonInstructions] = useState('Instrucciones');

    const [quoteOpened, setQuoteOpened] = useState(false);
    const [active, setActive] = useState(-1);

    let [total, setCount] = useState(0);
    let [totalQuote, setTotalQuote] = useState(0);
    const node:any = new GetInformationFloor();
    const delay = ms => new Promise(res => setTimeout(res, ms));

    useEffect(() => {
        if (category) {
            getCurrentFurnitureData(category); 
        } 
    }, [category])

    useEffect(() => {        
        if(currentFurnitureData.length <= 0) return;   
        setGondolas();
        setGondolas2();
        calculateQuote();
    }, [currentFurnitureData])

    useEffect(() => {
        if(categories && categories[0] && categories[0]._id) {
            setCategory(categories[0]._id)
        } else {
            showNotification({
                title: '',
                message: "Verifique su conexión a internet",
                styles: (theme) => ({
                    root: { '&::before': { backgroundColor: '#b2292e' } },
                    title: { color: '#b2292e' },
                    description: { color: '#b2292e !important' }
                }),
            })
        }
    }, [categories]);

    const setGondolas = async () => {

        let gondolas1 = currentFurnitureData.filter((g:any)=>g.isGondola == true);
        await node.execute();
        total = node.receiver.totalPrices;
        setCount(total);

        const countsById = {};
        node.receiver.furnitures.forEach(f  => {
            countsById[f.data._id] = (countsById[f.data._id] || 0) + 1;
        });

        const finalArray = Object.entries(countsById)
        .map(([_id, count]) => ({ _id, count }))
        .sort((a:any, b:any) => b.count - a.count);

        finalArray.forEach(f => {
            if(document.getElementById("count-"+f._id)){
                document.getElementById("count-"+f._id).innerHTML = f.count.toString();
            }            
        })

        setCards(gondolas1.map((item,index) =>
        (
            <>
            <Tooltip onClick={() => {
                            let action = new AddFurnitureAction(item);
                            action.execute();
                            setGondolas();
                            TransformLayer.Instance.deselect();
                        }} key={index} label="¡Da clic para agregar!">
                <FurnitureItem data={item} key={item._id}></FurnitureItem>
            </Tooltip>
            <div style={{textAlign:"center", alignSelf:"center"}}>
                <SimpleGrid key={`4${item._id}-${index}`} style={{gap:0, textAlign:"center", zIndex:99999, alignItems:"center"}} cols={3}>
                    <div>
                        <Button style={{borderRadius:"30px", padding:"10px", height:"26px", backgroundColor:"rgb(178, 41, 46)"}} onClick={() => {
                            node.execute();
                            let actualFurniture = [];
                            node.receiver.furnitures.forEach(f  => {
                                if(f.data._id == item._id){
                                    actualFurniture.push(f.id);
                                }
                                
                            });

                            if(actualFurniture[actualFurniture.length - 1]){
                                let action = new DeleteFurnitureAction(actualFurniture[actualFurniture.length - 1]);
                                action.execute();
                                setGondolas();
                            }
                            
                            TransformLayer.Instance.deselect();
                        }}>
                            -
                        </Button>
                    </div>
                    
                    <Text id={"count-"+item._id} style={{textAlign:"center", alignItems:"center"}}>
                        {finalArray[finalArray.findIndex(x => +x._id == +item._id)]?.count ? finalArray[finalArray.findIndex(x => +x._id == +item._id)].count : 0}
                    </Text>
                    <div>
                        <Button style={{borderRadius:"30px", padding:"5px", height:"26px", backgroundColor:"rgb(178, 41, 46)", zIndex:9999}} onClick={() => {
                            let action = new AddFurnitureAction(item);
                            action.execute();
                            setGondolas();
                            TransformLayer.Instance.deselect();
                        }}>
                            +
                        </Button>
                    </div>
                </SimpleGrid>
                <Text>X {currencyFormat(item.price)}</Text>
            </div>
            
            </>   
        )
        ));
    }

    const setGondolas2 = async () =>{
        let gondolas2 = currentFurnitureData.filter((g:any)=>g.isGondola == false);
        await node.execute();
        total = node.receiver.totalPrices;
        setCount(total);

        const countsById = {};
        node.receiver.furnitures.forEach(f  => {
            countsById[f.data._id] = (countsById[f.data._id] || 0) + 1;
        });

        const finalArray = Object.entries(countsById)
        .map(([_id, count]) => ({ _id, count }))
        .sort((a:any, b:any) => b.count - a.count);
        
        setCards2(gondolas2.map((item,index) =>
        (
            <>
            <Text key={`1${item._id}-${index}`} style={{ fontSize: 14, marginTop: 10 }}>{item.name}: </Text>
            <SimpleGrid key={`2${item._id}-${index}`} style={{gap:0, textAlign:"center", zIndex:99999, alignItems:"center"}} cols={3}>
                <div>
                    <Button style={{borderRadius:"30px", padding:"10px", height:"26px", backgroundColor:"rgb(178, 41, 46)"}} onClick={() => {
                        node.execute();
                        let actualFurniture = [];
                        node.receiver.furnitures.forEach(f  => {
                            if(f.data._id == item._id){
                                actualFurniture.push(f.id);
                            }
                            
                        });

                        if(actualFurniture[actualFurniture.length - 1]){
                            let action = new DeleteFurnitureAction(actualFurniture[actualFurniture.length - 1]);
                            action.execute();
                            setGondolas2();
                        }
                        TransformLayer.Instance.deselect();               
                    }}>
                        -
                    </Button>
                </div>
                
                <Text style={{textAlign:"center", alignItems:"center"}}>
                    {finalArray[finalArray.findIndex(x => +x._id == +item._id)]?.count ? finalArray[finalArray.findIndex(x => +x._id == +item._id)].count : 0}
                </Text>
                <div>
                    <Button style={{borderRadius:"30px", padding:"5px", height:"26px", backgroundColor:"rgb(178, 41, 46)"}} onClick={() => {
                        let action = new AddFurnitureAction(item);
                        action.execute();
                        setGondolas2();
                        TransformLayer.Instance.deselect();
                    }}>
                        +
                    </Button>
                </div>
            </SimpleGrid>
            <Text key={`3${item._id}-${index}`} style={{ fontSize: 14, marginTop: 0, display:"flex", alignItems:"center" }}>X {currencyFormat(item.price)}</Text>            
            </>          
        )
        )); 
    }

    const calculateQuote = () => {
        node.execute();
        total = node.receiver.totalPrices;
        setCount(total);
    }

    return (<>
        <Navbar.Section style={{ width: "400", height:"100%"}}grow mx="-xs" px="xs">
            <ScrollArea style={{ width: "400", height:"90%", paddingRight:30}}>

                <Image src={TammexLogo} style={{width:'80%', marginTop:-8}}/>
                <SimpleGrid style={{gap:0, textAlign:"center", zIndex:99999, alignItems:"center", backgroundColor: "#b2292e", padding: 8, borderRadius: 4}} cols={2}>
                    <Text style={{color:'white'}}>Agregar góndola</Text>
                    <Button style={{ width: "100%", backgroundColor: "#6495ED", padding: 2 }} leftIcon={!showInstructions ? <Help /> : <Minus />}
                        onClick={() =>{
                            setShowInstructions(!showInstructions);
                            setTextButtonInstructions(!showInstructions ? 'Ocultar' : 'Instrucciones');
                        }}>
                        {textButtonInstructions}
                    </Button> 
                </SimpleGrid>

                <Center style={{ padding:8, display: showInstructions ? 'block' : 'none' }}>
                    <Group noWrap={true}>
                        <p>&nbsp;- Agrega góndolas, entrepaños, ganchos y charolas con los botones de (+) y elíminalos con el botón de (-). </p>
                    </Group>
                    <Group noWrap={true}>
                        <p>&nbsp;- El total de la cotización se actualizará automáticamente.</p>
                    </Group>
                </Center>

                <Center style={{ marginTop: 30 }}>
                    <small style={{fontWeight:"bold"}}>Precios expresados en MXN (pesos mexicanos).</small>
                </Center>

                <SimpleGrid style={{ padding: 3 }} cols={2}>
                    <Text style={{fontWeight: "bold", marginTop: 10}}>Subtotal:</Text>
                    <Text id={"subtotal-0"} style={{fontWeight: "bold", marginTop: 10, placeSelf:"end"}}>{currencyFormat(total)}</Text>        
                </SimpleGrid>
                <SimpleGrid style={{ padding: 3 }} cols={2}>
                    <Text style={{fontWeight: "bold", marginTop: 10}}>IVA (16%):</Text>  
                    <Text id={"iva-0"} style={{fontWeight: "bold", marginTop: 10, placeSelf:"end"}}>{currencyFormat((total/100)*16)}</Text>        
                </SimpleGrid>
                <SimpleGrid style={{ padding: 3 }} cols={2}>
                    <Text style={{fontWeight: "bold", marginTop: 10}}>Total:</Text>  
                    <Text id={"total-0"} style={{fontWeight: "bold", marginTop: 10, placeSelf:"end"}}>{currencyFormat(total + ((total/100)*16))}</Text>         
                </SimpleGrid>              
                  
                <Text style={{ marginTop: 30 }} className="text-bold-justify">Incorpora una góndola al plano de diseño de mobiliario para tu tienda</Text>
                <SimpleGrid style={{ padding: 5 }} cols={2}>
                    {cards}               
                </SimpleGrid>

                <Text className="text-bold-justify" style={{marginTop:"50px"}}>¡Optimiza tu cotización sumando los entrepaños, ganchos y charolas necesarios para tus góndolas!</Text>

                <SimpleGrid style={{ padding: 5 }} cols={3}>
                    {cards2}                 
                </SimpleGrid>

                <Center style={{ marginTop: 30 }}>
                    <Text style={{ fontSize: 20, fontWeight: "bold"}}>Información de la cotización</Text>
                </Center>
                <Center style={{ marginTop: 10 }}>
                    <small style={{fontWeight:"bold"}}>Precios expresados en MXN (pesos mexicanos).</small>
                </Center>

                <SimpleGrid style={{ padding: 3 }} cols={2}>
                    <Text style={{fontWeight: "bold", marginTop: 10}}>Subtotal:</Text>
                    <Text id={"subtotal-1"} style={{fontWeight: "bold", marginTop: 10, placeSelf:"end"}}>{currencyFormat(total)}</Text>        
                </SimpleGrid>
                <SimpleGrid style={{ padding: 3 }} cols={2}>
                    <Text style={{fontWeight: "bold", marginTop: 10}}>IVA (16%):</Text>  
                    <Text id={"iva-1"} style={{fontWeight: "bold", marginTop: 10, placeSelf:"end"}}>{currencyFormat((total/100)*16)}</Text>        
                </SimpleGrid>
                <SimpleGrid style={{ padding: 3 }} cols={2}>
                    <Text style={{fontWeight: "bold", marginTop: 10}}>Total:</Text>  
                    <Text id={"total-1"} style={{fontWeight: "bold", marginTop: 10, placeSelf:"end"}}>{currencyFormat(total + ((total/100)*16))}</Text>         
                </SimpleGrid>

                <SimpleGrid style={{ marginTop: 60 }} cols={2}>
                    <Center>
                        <Button
                            style={{ 
                            backgroundColor: "#b2292e", 
                            color: "white",
                            width:"100px",
                            height:"100px",
                            display: 'block'
                            }}
                            onClick={async () => {
                        
                            const node:any = new GetInformationFloor(); 
                            await node.execute();
                            totalQuote = node.receiver.totalPrices;
                            setTotalQuote(totalQuote);
                    
                            if(totalQuote <= 0){
                                cleanNotifications();
                                showNotification({
                                title: '',
                                message: 'Agregue al menos una góndola para descargar el plano',
                                styles: (theme) => ({
                                    root: { '&::before': { backgroundColor: '#b2292e' } },
                                    title: { color: '#b2292e' },
                                    description: { color: '#b2292e !important' }
                                }),
                                });
                                return;
                            }
                    
                            const countsById = {};
                            await node.receiver.furnitures.forEach(f  => {
                                countsById[f.data._id] = (countsById[f.data._id] || 0) + 1;
                            });
                    
                            const finalArray = await Object.entries(countsById)
                            .map(([_id, count]) => ({ _id, count }))
                            .sort((a:any, b:any) => b.count - a.count);
                    
                            let array = [];
                            await node.receiver.furnitures.forEach(f  => {
                                f.data.quantity = finalArray[finalArray.findIndex(x => +x._id == +f.data._id)]?.count ? finalArray[finalArray.findIndex(x => +x._id == +f.data._id)].count : 0;
                                if(!array.find(x=>x._id == f.data._id)){
                                    array.push(f.data);
                                }
                            });
                    
                            let action:any = new GetPlanImage();
                            await action.execute();
                    
                            let data = {
                                data: array,
                                total:totalQuote.toFixed(2),
                                imageBase64: action.receiver.planImage.currentSrc
                            };
                            useStore.setState({loading:true});
                            let res = await getQuoteDocument(data);
                            useStore.setState({loading:false});
                            if(res.data){
                                var win = window.open(res.data, '_blank');
                                win.focus();
                                cleanNotifications();
                                showNotification({
                                title: '',
                                message: 'Cotización descargada',
                                styles: (theme) => ({
                                    root: { '&::before': { backgroundColor: '#4caf50' } },
                                    title: { color: '#4caf50' },
                                    description: { color: '#4caf50 !important'  }
                                }),
                                });
                            }          
                    
                            }}>
                            <div>
                                <Download size={40}/>
                                <Text style={{ display:'flex', flex: 1, fontSize: 13, whiteSpace: "pre-wrap"  }}>Descargar plano</Text>
                            </div>
                        </Button>
                    </Center>
                    
                    <Center>
                        <Button
                            style={{ 
                                backgroundColor: "#b2292e", 
                                color: "white",
                                width:"100px",
                                height:"100px",
                                display: 'block'
                                }}
                            onClick={() => {
                            setQuoteOpened(true);
                            setActive(-1);
                            }}>
                            <div>
                                <DeviceFloppy size={40}/>
                                <Text style={{ display:'flex', flex: 1, fontSize: 13, whiteSpace: "pre-wrap" }}>Guardar y enviar</Text>
                            </div>
                        </Button>
                    </Center>
                    
                    
                </SimpleGrid>

                <Text style={{textAlignLast:"center", marginTop:60, fontSize:20}}><a href="https://tammex.com/" target="_blank">Volver al sitio</a></Text>              
            </ScrollArea>

            <Drawer
                opened={quoteOpened}
                position='right'
                onClose={() => {setQuoteOpened(false)}}
                title="Cotización"
                padding="xl"
                size={400}
                overlayOpacity={0}>
                <Quote />
            </Drawer>

        </Navbar.Section>        
    </>)
}

export const setGondolasEx = async () => {
    //FurnitureAddPanel();
}

function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
