import { FloorPlan } from "../objects/FloorPlan";
import { WallNode } from "../objects/Walls/WallNode";
import { Action } from "./Action";
import { SaveAction } from "./SaveAction";

// Add wall between two nodes of FloorPlan
export class AddWallAction implements Action {
    
    private leftNode:number;
    private rightNode: number;
    private receiver:FloorPlan;   

    constructor(left:WallNode, right:WallNode) {
        this.leftNode = left.getId();
        this.rightNode = right.getId();
        this.receiver = FloorPlan.Instance;
    }

    public execute() {
        this.receiver.actions.push(this);
        let wall = this.receiver.getWallNodeSeq().addWall(this.leftNode, this.rightNode);
        const node:any = new SaveAction();
        node.execute();
        
        return wall;
    }
}