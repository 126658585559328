import { useStore } from '../stores/EditorStore';
export const homePage = 'https://cotizador.pruebas.bar/';
export const endpoint = 'https://alpha-tammex.pruebas.bar/api';
export const endpointImages = 'https://alpha-tammex.pruebas.bar/products/';

export function sendQuote(data) {
    let address = useStore.getState().address;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            email: data.email,
            name: '',
            address: `${address.cp} ${address.state}, ${address.municipality}, ${address.city}. ${address.street} ${address.numberExt} ${address.numberInt} ${address.phone}`,
            store: data.data,
            subtotal:data.total,
            tax:0,
            total:data.total,
            settlement: address.settlement,
            zoneId: address.zoneId,
            stateId: address.id,
            imageBase64:data.imageBase64
        })
    };
    return fetch(endpoint+"/quote/sendMail", requestOptions)
        .then(response => response.json())
}

export function getProducts(zoneId) {
    return fetch(endpoint+"/products/get/"+zoneId)
        .then(response => response.json())
}

export function getAddressByCP(postalCode) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            cp: postalCode
        })
    };
    return fetch(endpoint+"/quote/address", requestOptions)
        .then(response => response.json())
}

export function getQuoteDocument(data) {
    let address = useStore.getState().address;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            email: data.email,
            name: '',
            address: `${address.cp} ${address.state}, ${address.municipality}, ${address.city}. ${address.street} ${address.numberExt} ${address.numberInt} ${address.phone}`,
            store: data.data,
            subtotal:data.total,
            tax:0,
            total:data.total,
            settlement: address.settlement,
            zoneId: address.zoneId,
            stateId: address.id,
            imageBase64:data.imageBase64
        })
    };

    return fetch(endpoint+"/quote/getPDF", requestOptions)
        .then(response => response.json())
}