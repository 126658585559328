import { useRef, useEffect } from "react";
import { Application } from "pixi.js";
import { Main } from "./editor/Main";
import { IViewportOptions } from 'pixi-viewport';
import { useStore } from "../stores/EditorStore";
import { createStyles } from "@mantine/core";
import { METER, Tool } from "./editor/constants";
import { Buffer } from 'buffer';
import { FloorPlan } from "./editor/objects/FloorPlan";
import { homePage } from "../api/api-client";

const useStyles = createStyles(() => ({
    inactive:{
        display:'none'
    }

}));
export let main: Main;

export function EditorRoot(props) {
    const ref = useRef<HTMLDivElement>(null);
    const {classes} = useStyles();
    useEffect(() => {
        
        // On first render create our application
        const app = new Application({
            view: document.getElementById("pixi-canvas") as HTMLCanvasElement,
            resolution: window.devicePixelRatio || 1,
            autoDensity: true,
            backgroundColor: 0xebebeb,
            antialias: true,
            resizeTo: window
        });

    
        app.view.oncontextmenu = (e) => {
            e.preventDefault();
        }

        const viewportSettings: IViewportOptions = {
            screenWidth: app.screen.width,
            screenHeight: app.screen.height,
            worldWidth: 50 * METER,
            worldHeight: 50 * METER,
            interaction: app.renderer.plugins.interaction,
            
        }
        main = new Main(viewportSettings);
         

        // Add app to DOM
        ref.current!.appendChild(app.view);
        // Start the PixiJS app
        app.start();
        app.stage.addChild(main)

        return () => {
            // On unload completely destroy the application and all of its children
            app.destroy(true, true);
        };
    }, []);

    try {
        let label = new URLSearchParams(window.location.search);
        let reload = label.get('reload');
        if(reload == 'true' && !useStore.getState().loaded){
            const items = JSON.parse(localStorage.getItem('dataFloorPlan'));
            let state = JSON.parse(Buffer.from(items, 'base64').toString('ascii'));
            if(state){
                useStore.setState({ mode: state.mode });
                useStore.setState({ activeTool: Tool.Edit });
                useStore.setState({ floor: state.floor });
                useStore.setState({ snap: state.snap });
                useStore.setState({ loading: state.loading });
                useStore.setState({ address: state.address });
                useStore.setState({ showToolbar: false });
                useStore.setState({ showHelper: state.showHelper });
                useStore.setState({ showWelcome: state.showWelcome });
                useStore.setState({ floorPlanArray: state.floorPlanArray });
                useStore.setState({ walls: state.walls });
                useStore.setState({ loaded: true});
                useStore.setState({ showToolbar: false });
                FloorPlan.Instance.reloadFloorPlan();
            }
        }        
    }catch (error) {
        console.log(error);
    }    

    return <div ref={ref} />;
}