import { Point } from "../../../helpers/Point";
import { FurnitureData } from "../../../stores/FurnitureStore";
import { FloorPlan } from "../objects/FloorPlan";
import { Wall } from "../objects/Walls/Wall";
import { Action } from "./Action";
import { cleanNotifications, showNotification } from '@mantine/notifications';
import { SaveAction } from "./SaveAction";

export class AddFurnitureAction implements Action{

    obj: FurnitureData;
    attachedTo: Wall;
    coords: Point;
    attachedToLeft: number;
    attachedToRight: number;
    private receiver:FloorPlan;

    constructor(obj: FurnitureData, attachedTo?: Wall, coords?: Point, attachedToLeft?:number, attachedToRight?:number) {
        this.obj = obj;
        this.attachedTo = attachedTo;
        this.coords = coords;
        this.attachedToLeft = attachedToLeft;
        this.attachedToRight = attachedToRight;
        this.receiver = FloorPlan.Instance;
        

        cleanNotifications();
        showNotification({
            title: '',
            message: `Se agregó ${this.obj.name} correctamente a la cotización.`,
            styles: (theme) => ({
                root: { '&::before': { backgroundColor: '#4caf50' } },
                title: { color: '#4caf50' },
                description: { color: '#4caf50 !important'  }
            }),
        });     
    }

    public async execute() {
        this.receiver.addFurniture(this.obj, this.attachedTo, this.coords, this.attachedToLeft, this.attachedToRight);
        this.receiver.actions.push(this);
        const node:any = new SaveAction();
        node.execute();
    }
}