import { IViewportOptions, PluginManager, Viewport } from "pixi-viewport";
import { Application, InteractionEvent, isMobile, Loader, Point, TilingSprite } from "pixi.js";
import { FloorPlan } from "./objects/FloorPlan";
import { TransformLayer } from "./objects/TransformControls/TransformLayer";
import { useStore } from "../../stores/EditorStore";
import { AddNodeAction } from "./actions/AddNodeAction";
import { AddWallManager } from "./actions/AddWallManager";
import { viewportX, viewportY } from "../../helpers/ViewportCoordinates";
import { Tool } from "./constants";
import { Pointer } from "./Pointer";
import { Preview } from "./actions/MeasureToolManager";
import { showNotification } from "@mantine/notifications";
import { DeviceFloppy } from "tabler-icons-react";

export class Main extends Viewport {
    private floorPlan: FloorPlan;
    public static viewportPluginManager: PluginManager;
    public static app: Application;
    transformLayer: TransformLayer;
    addWallManager: AddWallManager;
    bkgPattern: TilingSprite;
    public pointer: Pointer;
    public preview: Preview;
    constructor(options: IViewportOptions) {
        super(options);

        // connect the events
        Loader.shared.onComplete.once(this.setup, this);
        // Start loading!
        Loader.shared.load();
        this.preview = new Preview();
        this.addChild(this.preview.getReference());
        this.cursor = "context-menu";
    }

    private setup() {
        Main.viewportPluginManager = this.plugins;
        this.drag({ mouseButtons: 'right' }).clamp({ direction: 'all' })
            .pinch()
            .wheel().clampZoom({ minScale: 0.1, maxScale: 6.0 })
        this.bkgPattern = TilingSprite.from("./pattern.svg", { width: this.worldWidth ?? 0, height: this.worldHeight ?? 0 });
        this.center = new Point(this.worldWidth / 2, this.worldHeight / 2)
        this.addChild(this.bkgPattern);

        this.floorPlan = FloorPlan.Instance;
        this.floorPlan.zoomEditor = (zoom:number,center:boolean) => {this.setZoom(zoom,center)};
        this.calculateZoom();
        this.floorPlan.worldWidth = this.worldWidth;
        this.floorPlan.worldHeight = this.worldHeight;
        this.addChild(this.floorPlan);

        this.transformLayer = TransformLayer.Instance;
        this.addChild(this.transformLayer)

        this.addWallManager = AddWallManager.Instance;
        this.addChild(this.addWallManager.preview.getReference())

        this.pointer = new Pointer();
        this.addChild(this.pointer);
        this.on("pointerdown", this.checkTools)
        this.on("pointermove", this.updatePreview)
        this.on("pointerup", this.updateEnd)

    }
    private updatePreview(ev: InteractionEvent) {
        this.addWallManager.updatePreview(ev);
        this.preview.updatePreview(ev);
        this.pointer.update(ev);
    }
    private updateEnd(ev: InteractionEvent) {
        switch (useStore.getState().activeTool) {
            case Tool.Measure:
                this.preview.set(undefined);
                this.pause = false;
                break;
            case Tool.WallAdd:
                if (!isMobile) {
                    this.pause = false;
                }
                break;
            case Tool.Edit:
                this.pause = false;
                break;
        }
    }
    private checkTools(ev: InteractionEvent) {
        ev.stopPropagation()
        if (ev.data.button == 2 || ev.data.button == 2) {
            return;
        }
        let point = { x: 0, y: 0 }
        switch (useStore.getState().activeTool) {
            case Tool.WallAdd:
                this.pause = true;
                point.x = viewportX(ev.data.global.x)
                point.y = viewportY(ev.data.global.y);
                let action = new AddNodeAction(undefined, point)
                action.execute();
                break;
            case Tool.Edit:
                // if (!isMobile) {
                //     this.pause = true;
                // }
                break;
            case Tool.Measure:
                this.pause = true;
                point.x = viewportX(ev.data.global.x)
                point.y = viewportY(ev.data.global.y);
                this.preview.set(point);
                break;
        }
    }
    public calculateZoom(){
        // hacemos una validacion del zoom
        let widthScreen = window.innerWidth;
        let heightScreen = window.innerHeight;
        let walls = useStore.getState().walls;
        const url = new URL(window.location.href);
        url.searchParams.delete('reload');
        window.history.replaceState(null,null,url);
        if(walls.length > 0){
            let sideA = walls[2].x - walls[0].x;
            let sideB = walls[2].y - walls[0].y;
            if(widthScreen > heightScreen){
                this.position.y = this.position.y+100;
            }
            let zoom = 1;
            let isSideABigger = false;
            if(sideA > widthScreen){
                isSideABigger = true;
                let zoomPercent = (sideA / widthScreen);
                zoom = 1 / zoomPercent;
            }
            if(sideB > heightScreen){
                if(isSideABigger){
                    let zoomPercent = (sideB / heightScreen);
                    let zoomY = 1 / zoomPercent;
                    zoom = Math.min(zoomY,zoom);
                }else{
                    let zoomPercent = (sideB / heightScreen);
                    zoom = 1 / zoomPercent;
                }
            }
            if(zoom!=1){
                this.setZoom(zoom-.1,true);
            }else{
                this.setZoom(1,true);
            }
        }
    }
}


let save = () => {
    let data = FloorPlan.Instance.save();
    localStorage.setItem('autosave', data);
}
// setInterval(autosave, 60000)

document.onkeydown = (e) => {
    if (e.code == "KeyS" && e.ctrlKey) {
        e.preventDefault();
        save();
        showNotification({
            title: '',
            message: "Plano guardado",
            icon: DeviceFloppy,
            styles: (theme) => ({
                root: { '&::before': { backgroundColor: '#4caf50' } },
                title: { color: '#4caf50' },
                description: { color: '#4caf50 !important'  }
            }),
        })
    }
};       