import { useEffect, useRef, useState } from 'react';
import { Modal, Button, useMantineTheme, Image, createStyles, Stack, Input, Text, SimpleGrid } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import TammexLogo from '../res/logo.png'
import { FloorPlan } from '../editor/editor/objects/FloorPlan';
import { cleanNotifications, showNotification } from '@mantine/notifications';
import { getAddressByCP } from '../api/api-client';
import { Address, useStore } from '../stores/EditorStore';
import { isMobile } from 'react-device-detect';
import { SaveAction } from '../editor/editor/actions/SaveAction';
import { Point } from "../helpers/Point";

export function WelcomeModal() {
  const image = <Image src={TammexLogo} />
  const useStyles = createStyles(() => ({
    padded: {
      padding: '4px'
    }

  }));
  const colorWarning = '#b2292e';
  const colorSuccess = '#339af0';
  const minWidth = 2;
  const maxWidth = 20;
  const minHeight = 2;
  const maxHeight = 20;

  const theme = useMantineTheme();
  const { classes } = useStyles();
  useEffect(() => {
  }, [])

  const notification = {
    title: "",
    message: ""
  }

  const meds = 100;

  const [height, setHeight] = useState('');
  const [heightMessage, setHeightMessage] = useState('');
  const heightRef = useRef(null);
  const heightMessages = {
    required: '* Ancho es requerido.',
    minLength: `* La medida minima de ancho es de ${minHeight} metros`,
    maxLength: `* La medida máxima de ancho es de ${maxHeight} metros`,
    pattern: '* Ingrese solo números sin espacios.',
  }
  const handleChangeHeight = (event) => {
    if(!Number.isNaN(event.target.value)){
      setHeightMessage(heightMessages.pattern);
      event.target.style.borderColor = colorWarning;
    }
    const re = /^[0-9\b]{0,2}$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      let heightN = Number(event.target.value);
      if(heightN <= maxHeight){
        setHeight(event.target.value);
        setHeightMessage('');
        event.target.style.borderColor = colorSuccess;
      }
      if(heightN > maxHeight){
        event.target.style.borderColor = colorWarning;
        setHeightMessage(heightMessages.maxLength);
      }
      if(heightN < minHeight){
        event.target.style.borderColor = colorWarning;
        setHeightMessage(heightMessages.minLength);
      }
    }
  };

  const [width, setWidth] = useState('');
  const [widthMessage, setWidthMessage] = useState('');
  const widthRef = useRef(null);
  const widthMessages = {
    required: '* Profundidad es requerido.',
    minLength: `* La medida minima de profundidad es de ${minWidth} metros`,
    maxLength: `* La medida máxima de profundidad es de ${maxWidth} metros`,
    pattern: '* Ingrese solo números sin espacios.',
  }
  const handleChangeWidth = (event) => {
    if(!Number.isNaN(Number(event.target.value))){
      event.target.style.borderColor = colorWarning;
      setWidthMessage(widthMessages.pattern);
    }
    const re = /^[0-9\b]{0,2}$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      let widthN = Number(event.target.value);
      if(widthN <= maxWidth){
        setWidth(event.target.value);
        event.target.style.borderColor = colorSuccess;
        setWidthMessage('');
      }
      if(widthN > maxWidth){
        event.target.style.borderColor = colorWarning;
        setWidthMessage(widthMessages.maxLength);
      }
      if(widthN < minWidth){
        event.target.style.borderColor = colorWarning;
        setWidthMessage(widthMessages.minLength);
      }
    }
  };

  // size screen
  const [widthScreen, setWidthScreen] = useState<number>(window.innerWidth);
  const [heightScreen, setHeightScreen] = useState<number>(window.innerHeight);
  // address
  const [cp, setCP] = useState('');
  const [cpMessage, setCPMessage] = useState('');
  const cpRef = useRef(null);
  const cpMessages = {
    noFound: 'Código postal no encontrado ingresé otro',
    required: '* Ingrese un código requerido.',
    minLength: '* El código postal debe tener 5 dígitos',
  }
  const [colony, setColony] = useState('');
  const [colonyMessage, setColonyMessage] = useState('');
  const colonyMessages = {
    required: '* Seleccione una colonia'
  }
  const colonyRef = useRef(null);
  const [streetMessage, setStreetMessage] = useState('');
  const [street, setStreet] = useState('');
  const streetRef = useRef(null);
  const streetMessages = {
    required: '* Calle es requerido.',
    minLength: '* Al menos 3 caracteres',
    maxLength: '* No más de 100 caracteres.',
  }
  const [numberExt, setNumberExt] = useState('');
  const [numberExtMessage, setNumberExtMessage] = useState('');
  const numberExtRef = useRef(null);
  const numberExtMessages = {
    required: '* Número exterior es requerido.',
    minLength: '* Al menos 1 caracteres',
    maxLength: '* No más de 10 caracteres',
    // pattern: '* Ingrese solo números.',
  }
  const [numberInt, setNumberInt] = useState('');
  const [numberIntMessage, setNumberIntMessage] = useState('');
  const numberIntRef = useRef(null);
  const numberIntMessages = {
    required: '* Número interior es requerido.',
    minLength: '* Al menos 1 caracteres',
    maxLength: '* No más de 10 caracteres',
    // pattern: '* Ingrese solo números.',
  }
  const [phone, setPhone] = useState('');
  const [phoneMessage, setPhoneMessage] = useState('');
  const phoneRef = useRef(null);
  const phoneMessages = {
    required: '* Teléfono es requerido.',
    minLength: '* Al menos 1 dígitos',
    maxLength: '* No más de 10 dígitos',
    pattern: '* Ingrese solo números.',
  }
  const [settlement, setSettlement] = useState({ id: 0 ,cp: '', settlement: '', street: '', numberExt: '', numberInt: '', phone: ''});
  const [settlements, setSettlements] = useState([]);

  const validateCP = (event) => {
    if(!Number.isNaN(event.target.value)){
      setCPMessage(cpMessages.required);
    }
    const re = /^[0-9\b]{0,5}$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      let cp = event.target.value;
      setCP(cp);
      if(cp.length == 5){
        setCPMessage('');
        event.target.style.borderColor = colorSuccess;
        useStore.setState({ loading: true });
        getAddressByCP(cp).then((response)=>{
          useStore.setState({ loading: false });
          if(response.data.length>0){
            setSettlements(response.data);
            if(colonyRef.current != null){
              colonyRef.current.style.borderColor = colorWarning;
              colonyRef.current.value = 0;
            }
            setColonyMessage(colonyMessages.required);
          }else{
            setSettlements([]);
            setCPMessage(cpMessages.noFound);
          }
        }).catch((e)=>{
          setCPMessage(cpMessages.noFound);
          useStore.setState({ loading: false });
        });
      }
      if(cp.length < 5){
        setCPMessage(cpMessages.minLength);
        event.target.style.borderColor = colorWarning;
      }
    } 
  }

  const validateNumberExt = (event) => {
    if (event.target.value.length <= 10) {
      if(event.target.value.length == 1){
        event.target.style.borderColor = colorWarning;
        setNumberExtMessage(numberExtMessages.minLength);
      }
      setNumberExtMessage('');
      event.target.style.borderColor = colorSuccess;
      setNumberExt(event.target.value);
    }
    if(event.target.value.length > 10){
      setNumberExtMessage(numberExtMessages.maxLength);
      event.target.style.borderColor = colorWarning;
    }
  }

  const validateNumberInt = (event) => {
    if (event.target.value.length <= 10) {
      if(event.target.value.length == 1){
        event.target.style.borderColor = colorWarning;
        setNumberIntMessage(numberIntMessages.minLength);
      }
      setNumberIntMessage('');
      event.target.style.borderColor = colorSuccess;
      setNumberInt(event.target.value);
    }
    if(event.target.value.length > 10){
      setNumberIntMessage(numberIntMessages.maxLength);
      event.target.style.borderColor = colorWarning;
    }
  }

  const validatePhone = (event) => {
    if(!Number.isNaN(event.target.value)){
      event.target.style.borderColor = colorWarning;
      setPhoneMessage(phoneMessages.pattern);
    }
    const re = /^[0-9\b]{0,10}$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      if(event.target.value.length == 1){
        event.target.style.borderColor = colorWarning;
        setPhoneMessage(phoneMessages.minLength);
      }
      setPhoneMessage('');
      event.target.style.borderColor = colorSuccess;
      setPhone(event.target.value);
    }
    if(event.target.value.length > 10){
      setPhoneMessage(phoneMessages.maxLength);
      event.target.style.borderColor = colorWarning;
    }
  }

  const validateStreet = (event) => {
    if(event.target.value.length < 3){
      event.target.style.borderColor = colorWarning;
      setStreetMessage(streetMessages.minLength);
    }
    if(event.target.value.length > 100){
      event.target.style.borderColor = colorWarning;
      setStreetMessage(streetMessages.maxLength);
    }
    if (event.target.value.length <= 100) {
      if(event.target.value.length >= 3){
        event.target.style.borderColor = colorSuccess;
        setStreetMessage('');
      }
      setStreet(event.target.value.replace(/\s{2,}/g, ' '));
    }
  }

  const selectSettlement = (event) => {
    let address = settlements.find((s:Address)=>s.id == event.target.value);
    setColonyMessage('');
    if(colonyRef.current != null){
      colonyRef.current.style.borderColor = colorSuccess;
    }
    setSettlement(address);
  }

  const showDataSettlement = () => {
    if(settlements?.length && settlements?.length > 0){
      let settlement = settlements[0];
      return (
        <>
          <div>
            <Text>Estado</Text>
            <Input placeholder='Estado' disabled value={settlement.state}></Input>
          </div>
          <div>
            <Text>Municipio</Text>
            <Input placeholder='Municipio' disabled value={settlement.municipality}></Input>
          </div>
          <div>
            <Text>Colonia *</Text>
            <select ref={colonyRef} className='mantine-g773hu' placeholder='Seleccione colonia' defaultValue={0} onChange={e => selectSettlement(e)} >
              <option key={0} disabled value={0}>Seleccione una opción</option>
              {settlements.map((s,index)=><option key={index++} value={s.id}>{s.settlement}</option>)}
            </select>
            <Text className='text-error'>{colonyMessage}</Text>
          </div>
        </>
      );
    }else{
      return <>
        <div>
          <Text>Estado</Text>
          <Input placeholder='Estado' disabled value={''}></Input>
        </div>
        <div>
          <Text>Municipio</Text>
          <Input placeholder='Municipio' disabled value={''}></Input>
        </div>
        <div>
          <Text>Colonia *</Text>
          <Input placeholder='Colonia' disabled value={''}></Input>
        </div>
      </>;
    }
  }

  const continueAction = () => {
    let message = ""
    if(!settlements || settlements.length == 0){
      if(!cp && cpRef.current != null){
        cpRef.current.style.borderColor = colorWarning;
        setCPMessage(cpMessages.required);
      }
      message = cpMessages.required;
    }
    if(settlement.settlement == ""){
      if(colonyRef.current != null){
        colonyRef.current.style.borderColor = colorWarning;
      }
      setColonyMessage(colonyMessages.required);
      message = colonyMessages.required;
    }
    if(street == ""){
      if(streetRef.current != null){
        streetRef.current.style.borderColor = colorWarning;
      }
      setStreetMessage(streetMessages.required);
      message = streetMessages.required;
    }
    if(numberExt == ""){
      if(numberExtRef.current != null){
        numberExtRef.current.style.borderColor = colorWarning;
      }
      setNumberExtMessage(numberExtMessages.required);
      message = numberExtMessages.required;
    }
    if(numberInt == ""){
      if(numberIntRef.current != null){
        numberIntRef.current.style.borderColor = colorWarning;
      }
      setNumberIntMessage(numberIntMessages.required);
      message = numberIntMessages.required;
    }
    if(phone == ""){
      if(phoneRef.current != null){
        phoneRef.current.style.borderColor = colorWarning;
      }
      setPhoneMessage(phoneMessages.required);
      message = phoneMessages.required;
    }
    if(!width || Number(width) < minWidth || Number(width) > maxWidth){
      message = widthMessages.required;
      if(widthRef.current){
        widthRef.current.style.borderColor = colorWarning;
      }
      setWidthMessage(widthMessages.required);
      
    }
    if(!height || Number(height) < minHeight || Number(height) > maxHeight){
      message = heightMessages.required;
      if(heightRef.current){
        heightRef.current.style.borderColor = colorWarning;
      }
      setHeightMessage(heightMessages.required);
    }
    if(message != ""){
      cleanNotifications();
      showNotification({
        title: '',
        message: "Los campos en * son obligatorios",
        styles: (theme) => ({
          root: { '&::before': { backgroundColor: '#b2292e' } },
          title: { color: '#b2292e' },
          description: { color: '#b2292e !important' }
        }),
      });
      return;
    }
    // save address
    let address = settlement;
    address.settlement = (address.id ? address.settlement : colony )
    address.numberExt = numberExt;
    address.numberInt = numberInt;
    address.phone = phone;
    address.street = street;
    useStore.setState({ address: address });

    if(!height && !width){
    }else{
      let sideA = (+width * meds);
      let sideB = (+height * meds);
      let pointInitX = (FloorPlan.Instance.worldHeight/2) - (sideA/2);
      let pointInitY = (FloorPlan.Instance.worldWidth/2) - (sideB/2);
      let zoom = 1;
      let isSideABigger = false;
      let isSideBBigger = false;
      if(sideA > widthScreen){
        isSideABigger = true;
        let zoomPercent = (sideA / widthScreen);
        zoom = 1 / zoomPercent;
      }
      if(sideB > heightScreen){
        if(isSideABigger){
          let zoomPercent = (sideB / heightScreen);
          let zoomY = 1 / zoomPercent;
          zoom = Math.min(zoomY,zoom);
        }else{
          let zoomPercent = (sideB / heightScreen);
          zoom = 1 / zoomPercent;
        }
      }
      if(zoom!=1){
        FloorPlan.Instance.zoomEditor(zoom-.1,true);
      }else{
        FloorPlan.Instance.zoomEditor(1,true);
      }
      let w = pointInitX + sideA + 20;
      let h = pointInitY + sideB + 20;
      let wall = Array<Point>({x:pointInitX,y:pointInitY},{x:w,y:pointInitY},{x:w,y:h},{x:pointInitX,y:h});
      useStore.setState({walls:wall});
      FloorPlan.Instance.load('{"floors":[{"furnitureArray":[],"wallNodes":[{"id":18,"x":'+(pointInitX)+',"y":'+(pointInitY)+'},{"id":19,"x":'+(pointInitX)+',"y":'+ h +'},{"id":20,"x":'+ w +',"y":'+ h +'},{"id":21,"x":'+ w +',"y":'+(pointInitY)+'}],"wallNodeLinks":[[9,[]],[10,[]],[11,[]],[12,[]],[13,[]],[14,[]],[15,[]],[16,[]],[17,[]],[18,[19,21]],[19,[20]],[20,[21]],[21,[]]]}],"furnitureId":0,"wallNodeId":21}');
      const node:any = new SaveAction();
      node.execute();
    }
    useStore.setState({ showWelcome: false, showHelper: true, showToolbar: true });
  }

  return (
    <>
      <Modal className={classes.padded}
        closeOnClickOutside={false}
        closeOnEscape={false}
        opened={useStore(state=>state.showWelcome)}
        withCloseButton={false}
        overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
        overlayOpacity={0.55}
        centered
        onClose={() => {
          showNotification(notification);
          useStore.setState({ showWelcome: false, showHelper: true });
        }}>
        <Stack spacing="xs">
          {image}
          <Text style={{ fontWeight: 'bold', display: (isMobile ? 'block' : 'none' ) }}>* Para tener una mejor experiencia te recomendamos mantener la pantalla en modo vertical.</Text>
          <Text style={{ fontWeight: 'bold', textAlign:'center', fontSize:'19px' }}>Cotizador para el mobiliario de mi tienda</Text>
          <Text style={{ fontWeight: 'bold' }}>Ingrese la dirección de la sucursal</Text>
          <SimpleGrid cols={2}>
            <div>
              <Text>Código Postal *</Text>
              <Input ref={cpRef} onInput={e=>validateCP(e)} value={cp} placeholder='Código Postal'></Input>
              <Text className='text-error'>{cpMessage}</Text>
            </div>
            {showDataSettlement()}
          </SimpleGrid>
          <SimpleGrid cols={2}>
            <div>
              <Text>Calle *</Text>
              <Input ref={streetRef} value={street} onChange={e=>validateStreet(e)} placeholder='Calle'></Input>
              <Text className='text-error'>{streetMessage}</Text>
            </div>
            <div>
              <Text>Número exterior *</Text>
              <Input ref={numberExtRef} value={numberExt} onChange={e=>validateNumberExt(e)} placeholder='Número ext.'></Input>
              <Text className='text-error'>{numberExtMessage}</Text>
            </div>
          </SimpleGrid>
          <SimpleGrid cols={2}>
            <div>
              <Text>Número interior *</Text>
              <Input ref={numberIntRef} value={numberInt} onChange={e=>validateNumberInt(e)} placeholder='Número int.'></Input>
              <Text className='text-error'>{numberIntMessage}</Text>
            </div>
            <div>
              <Text>Teléfono *</Text>
              <Input ref={phoneRef} value={phone} onChange={e=>validatePhone(e)} placeholder='Teléfono'></Input>
              <Text className='text-error'>{phoneMessage}</Text>
            </div>
          </SimpleGrid>
          <Text style={{ fontWeight: 'bold' }}>Ingresa las medidas de tu Local Comercial (m2)</Text>
          <Text className='text-error'>* Las medidas minimas son de {minWidth}x{minHeight} (m2) y un máximo de {maxWidth}x{maxHeight} (m2), Ingresa solo números enteros.</Text>
          <div className='container-center flex-column'>
            <div style={{ width: '45%' }}>
              <Text>Ancho(m) *</Text>
              <Input ref={heightRef} placeholder='0' onChange={handleChangeHeight} value={height}></Input>
              <Text className='text-error'>{heightMessage}</Text>
            </div>
            <div style={{ width: '10%' }} className='container-center'>X</div>
            <div style={{ width: '45%' }}>
              <Text>Profundo(m) *</Text>
              <Input ref={widthRef} placeholder='0' onChange={handleChangeWidth} value={width}></Input>
              <Text className='text-error'>{widthMessage}</Text>
            </div>
          </div>
          <Button
            onClick={continueAction} leftIcon={<Plus />} style={{ backgroundColor: "#b2292e", color: "white" }}>
            Continuar
          </Button>
          <Text className='text-center'><a href="https://www.tammex.com/aviso-de-privacidad" target="_blank">Consulta nuestra política de privacidad</a></Text>
        </Stack>
      </Modal>

    </>
  );
}