import { useEffect, useRef, useState } from 'react';
import { Navbar, createStyles, Group, Menu, Divider, Drawer, Image, Button, Tooltip } from '@mantine/core';
import { Armchair, BorderLeft, DeviceFloppy, Pencil, Eraser, Rotate, ClearAll, Download, ArrowBack, Plus, RowInsertBottom } from 'tabler-icons-react';
import { cleanNotifications, showNotification } from '@mantine/notifications';
import { useStore } from "../../stores/EditorStore";
import { Tool } from '../../editor/editor/constants';
import { FurnitureAddPanel } from '../FurnitureControls/FurnitureAddPanel/FurnitureAddPanel';
import { NavbarLink } from '../NavbarLink';
import { HelpDialog } from '../HelpDialog';
import { useFurnitureStore } from '../../stores/FurnitureStore';
import { Quote } from '../FurnitureControls/FurnitureAddPanel/Quote';
import TammexLogo from '../../res/logo.png'
import { isMobile } from 'react-device-detect';
import { FloorPlan } from '../../editor/editor/objects/FloorPlan';
import { GetPlanImage } from "../../editor/editor/actions/GetPlanImage";
import { GetInformationFloor } from '../../editor/editor/actions/GetInformationFloor';
import { getQuoteDocument, homePage } from '../../api/api-client';
import { Buffer } from 'buffer';
import { TransformLayer } from '../../editor/editor/objects/TransformControls/TransformLayer';

const useStyles = createStyles((theme) => ({
  link: {
    width: 40,
    height: 40,
    borderRadius: theme.radius.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
    },
  },

  active: {
    '&, &:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors[theme.primaryColor][9], 0.25)
          : theme.colors[theme.primaryColor][0],
      color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 7],
    },
  },
}));

const modes = [
  { icon: BorderLeft, label: 'Dibujar pared', tool: Tool.WallAdd, visible:true },// 0
  { icon: RowInsertBottom, label: 'Agregar góndola', tool: Tool.FurnitureAdd, visible: isMobile },// 1
  { icon: Pencil, label: 'Editar', tool: Tool.Edit, visible:true },// 2
  { icon: Eraser, label: 'Borrar', tool: Tool.Remove, visible:true },// 3
  { icon: ClearAll, label: 'Limpiar ', tool: Tool.Clear, visible:true },// 4
  { icon: Rotate, label: 'Reiniciar', tool: Tool.Reset, visible:true },// 5
  { icon: ArrowBack, label: 'Deshacer', tool: Tool.Undo, visible:true },// 5
];

export function ToolNavbar() {
  const [active, setActive] = useState(-1);
  const { setTool, floor } = useStore()
  const [drawerOpened, setDrawerOpened] = useState(false);
  
  const [quoteOpened, setQuoteOpened] = useState(false);
  const {getCategories} = useFurnitureStore();
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  let navHeight = height;
  let navWidth = 70;
  let secondGroup:'column'|'row' = "column";
  let secondGroupWidth:string = "104px";

  //quote
  let [totalQuote, setTotalQuote] = useState(0);
  const [finalData, setFinalData] = useState([]);
  const delay = ms => new Promise(res => setTimeout(res, ms));
  

  const toolModes = modes.map((link, index) => (
    <NavbarLink      
      {...link}
      visible={link.visible}
      key={link.label}
      active={index === active}
      onClick={() => {
        switch (link.tool) {
          case Tool.WallAdd:
            setActive(index);
            setTool(link.tool);
            cleanNotifications();
            useStore.setState({ showHelper: true });
            break;
          case Tool.FurnitureAdd:
            useStore.setState({ showToolbar: true, showHelper: true });
            setTool(link.tool);
            setActive(index);
            cleanNotifications();
            setTool(Tool.Edit);
            setActive(Tool.Edit);
            break;
          case Tool.Edit:
            setActive(index);
            setTool(link.tool);
            cleanNotifications();
            useStore.setState({ showHelper: true });
            break;
          case Tool.Remove:
            setActive(index);
            setTool(link.tool);
            cleanNotifications();
            useStore.setState({ showHelper: true });
            break;
          case Tool.Clear:
            getCategories(); 
            useStore.setState({ showToolbar: false });
            FloorPlan.Instance.resetAll();
            break;
          case Tool.Reset:
            window.location.replace(homePage);
            break;
          case Tool.Undo:
            useStore.setState({ showToolbar: false });
            FloorPlan.Instance.undoFloorPlan();
            TransformLayer.Instance.deselect();
            break;
        }
      }
      }
    />
  ));

  if(isMobile){
    navWidth = width;
    if(width > height){
      navHeight = 70;
      secondGroup = "row";
      secondGroupWidth = "160px";
    }else{
      navHeight = 140;
      secondGroup = "column";
      secondGroupWidth = "104px";
    }
  }

  useEffect(() => {
    setTool(Tool.FirstEdit);
    setActive(Tool.Edit);

    const handleResize = (e) => {
      if(window.innerWidth < window.innerHeight){
        // alert("Para tener una mejor experiencia te recomendamos mantener la pantalla en modo vertical.");        
      }
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);

      let data = {
        mode:useStore.getState().mode,
        activeTool:useStore.getState().activeTool,
        floor:useStore.getState().floor,
        snap:useStore.getState().snap,
        loading:useStore.getState().loading,
        address:useStore.getState().address,
        showToolbar:useStore.getState().showToolbar,
        showHelper:useStore.getState().showHelper,
        showWelcome:useStore.getState().showWelcome,
        floorPlanArray:useStore.getState().floorPlanArray,
        walls:useStore.getState().walls
      }

      const encodedString = Buffer.from(JSON.stringify(data)).toString('base64');
      localStorage.setItem('dataFloorPlan', JSON.stringify(encodedString));
      window.location.href=`?reload=true`;
    };
    window.addEventListener('orientationchange', handleResize);
    return () => {
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return (<div style={{ position: 'absolute' }}>   
    <Navbar height={navHeight} width={{ base: navWidth }} p="md" style={{padding:"0px", backgroundColor: "#b2292e", flexDirection: (isMobile ? 'row' : 'column') }}>
    <Image src={TammexLogo}
      style={{
        width: isMobile ? "150px" : "300px", 
        position: "fixed",
        bottom: "0",
        right: "0",
        opacity: "0.4",
        display: 'block'
        }} 
      />

      <Tooltip label={'Agregar góndola'} position="left" withArrow transitionDuration={0} style={{position: "fixed",top: "60px",right: "100px",}} >
        <Button
            style={{ 
            backgroundColor: "#b2292e", 
            color: "white",
            position: "fixed",
            top: "20px",
            right: "20px",
            width:"80px",
            height:"80px",
            display: isMobile ? 'none' : 'block'
            }}
            onClick={() => {
              useStore.setState({ showToolbar: true, showHelper: true });
              setTool(Tool.FurnitureAdd);
              cleanNotifications();
              setTool(Tool.Edit);
              setActive(Tool.Edit);
            }}>
              <Plus size={40}/>
        </Button>
      </Tooltip>
      
      <Navbar.Section grow style={{alignSelf: 'center'}}>
        <Group direction={
          isMobile ? 'row' : 'column'} 
          align="center" 
          spacing={isMobile ? 3 : 0} 
          style={{color: "white"}}>
          {/* <AddMenu setter={setterAction} /> */}
          <Drawer
            closeOnClickOutside={false}
            withOverlay={false}
            opened={useStore(state=>state.showToolbar)}
            position='right'
            onClose={() => {
              getCategories(); 
              useStore.setState({ showToolbar: false }); 
              setTool(Tool.Edit);
              setActive(Tool.Edit);
            }}
            title=""
            padding="xl"
            size={400}
            overlayOpacity={0}>
            <FurnitureAddPanel />
          </Drawer>

          <Drawer
            opened={quoteOpened}
            position='right'
            onClose={() => {setQuoteOpened(false)}}
            title="Cotización"
            padding="xl"
            size={400}
            overlayOpacity={0}>
            <Quote />
          </Drawer>
          {toolModes}
        </Group>
      </Navbar.Section>
      <Navbar.Section style={{alignSelf: 'center'}}>
        <Group direction={secondGroup} align="center" spacing={0} style={{ width: secondGroupWidth }}>
        <NavbarLink icon={Download} label={ isMobile ? "Descargar" : "Descargar plano"} onClick={async () => {
          
          const node:any = new GetInformationFloor(); 
          await node.execute();
          totalQuote = node.receiver.totalPrices;
          setTotalQuote(totalQuote);

          if(totalQuote <= 0){
            cleanNotifications();
            showNotification({
              title: '',
              message: 'Agregue al menos una góndola para descargar el plano',
              styles: (theme) => ({
                root: { '&::before': { backgroundColor: '#b2292e' } },
                title: { color: '#b2292e' },
                description: { color: '#b2292e !important' }
              }),
            });
            return;
          }

          const countsById = {};
          await node.receiver.furnitures.forEach(f  => {
              countsById[f.data._id] = (countsById[f.data._id] || 0) + 1;
          });

          const finalArray = await Object.entries(countsById)
          .map(([_id, count]) => ({ _id, count }))
          .sort((a:any, b:any) => b.count - a.count);

          let array = [];
          await node.receiver.furnitures.forEach(f  => {
              f.data.quantity = finalArray[finalArray.findIndex(x => +x._id == +f.data._id)]?.count ? finalArray[finalArray.findIndex(x => +x._id == +f.data._id)].count : 0;
              if(!array.find(x=>x._id == f.data._id)){
                  array.push(f.data);
              }
          });

          let action:any = new GetPlanImage();
          await action.execute();

          let data = {
              data: array,
              total:totalQuote.toFixed(2),
              imageBase64: action.receiver.planImage.currentSrc
          };
          useStore.setState({loading:true});
          let res = await getQuoteDocument(data);
          useStore.setState({loading:false});
          if(res.data){
            var win = window.open(res.data, '_blank');
            win.focus();
            cleanNotifications();
            showNotification({
              title: '',
              message: 'Cotización descargada',
              styles: (theme) => ({
                root: { '&::before': { backgroundColor: '#4caf50' } },
                title: { color: '#4caf50' },
                description: { color: '#4caf50 !important'  }
              }),
            });
          }          

        }} />
          <NavbarLink icon={DeviceFloppy} label={ isMobile ? "Enviar" : "Guardar y enviar cotización"} onClick={() => {
            setQuoteOpened(true);
            setActive(-1);
          }} />          
          <HelpDialog />
        </Group>
      </Navbar.Section>
    </Navbar>
  </div>
  );
}