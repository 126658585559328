import { useEffect } from 'react';
import './App.css';
import { PageLayout } from './ui/Layout/PageLayout';
import {useFurnitureStore} from './stores/FurnitureStore'
import { NotificationsProvider } from '@mantine/notifications';
import { LoadingOverlay } from '@mantine/core';
import { useStore } from './stores/EditorStore';

function App() {
  const {getCategories} = useFurnitureStore();

  useEffect(() => {
    getCategories()
  },[])
  return (
    <>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={useStore(state=>state.loading)} />
        <NotificationsProvider position='top-center'>
          <PageLayout />
        </NotificationsProvider>
      </div>
    </>
  );
}
export default App;
