import create from 'zustand'
import { AddWallManager } from '../editor/editor/actions/AddWallManager';
import { Tool } from '../editor/editor/constants';
import { Point } from '../helpers/Point';


export enum ToolMode {
    FurnitureMode,
    WallMode,
    ViewMode
};

export interface zone {
  active:number,
  zone:string,
  zoneId:number
}

export interface Address {
  id:number,
  cp:string,
  settlement:string,
  municipality?:string,
  city?:string,
  state?:string,
  zoneId?:number,
  street:string,
  numberExt:string,
  numberInt:string,
  phone:string,
  number?:string,
}

export interface EditorStore {
    mode:ToolMode,
    floor:number,
    activeTool:Tool,
    snap:boolean,
    loading:boolean,
    address:Address,
    showToolbar:boolean,
    showHelper:boolean,
    showWelcome:boolean,
    floorPlanArray:[],
    walls:Array<Point>,
    loaded:boolean,
    setMode: (mode:ToolMode) => void,
    setTool: (tool: Tool) => void,
    setFloor: (floor:number) => void,
    setSnap: (snap:boolean) => void,
    setLoading: (snap:boolean) => void,
    setAddress: (address:Address) => void,
    setShowToolbar: (showToolbar:boolean) => void,
    setShowHelper: (showHelper:boolean) => void,
    setShowWelcome: (showWelcome:boolean) => void,
    setFloorPlanArray: (floorPlanArray:[]) => void,
    setWalls: (walls:Array<Point>) => void,
    setLoaded: (walls:boolean) => void,
}


export const useStore = create<EditorStore>(set => ({
  mode: ToolMode.FurnitureMode,
  activeTool: Tool.View,
  floor: 0,
  snap: true,
  loading: false,
  address: { id: 0 ,cp: '', settlement: '', street: '', numberExt: '', numberInt: '', phone: ''},
  showToolbar: false,
  showHelper: false,
  showWelcome: true,
  floorPlanArray: [],
  walls:[],
  loaded:false,
  setMode: (mode: ToolMode) => {
    set(() => ({
      mode: mode
    }));    
  },
  setFloor: (floor:number) => {
    set(() => ({
      floor: floor
    }));    
  },
  setTool: (tool: Tool) => {
    set(() => ({
      activeTool: tool
    })); 
    AddWallManager.Instance.resetTools()
  },
  setSnap: (snap:boolean) => {
    set(() => ({
      snap: snap
    }));    
  },
  setLoading: (loading:boolean) => {
    set(() => ({
      loading: loading
    }));    
  },
  setAddress: (address:Address) => {
    set(() => ({
      address: address
    }));    
  },
  setShowToolbar: (showToolbar:boolean) => {
    set(() => ({
      showToolbar: showToolbar
    }));    
  },
  setShowHelper: (showHelper:boolean) => {
    set(() => ({
      showHelper: showHelper
    }));    
  },
  setShowWelcome: (showWelcome:boolean) => {
    set(() => ({
      showWelcome: showWelcome
    }));    
  },
  setFloorPlanArray: (floorPlanArray:[]) => {
    set(() => ({
      floorPlanArray: floorPlanArray
    }));    
  },
  setWalls: (walls:Array<Point>) => {
    set(() => ({
      walls: walls
    }));    
  },
  setLoaded: (loaded:boolean) => {
    set(() => ({
      loaded: loaded
    }));    
  }
}))
