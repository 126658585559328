import saveAs from "file-saver";
import { FloorPlan } from "../objects/FloorPlan";
import { Action } from "./Action";
import { sendQuote } from "../../../api/api-client";

export class SaveAction implements Action {

    private receiver: FloorPlan;
    constructor() {
        this.receiver = FloorPlan.Instance;
    }

    public async execute() {
        let data = this.receiver.save();
        return data;
    }
}