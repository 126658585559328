import { Graphics, IFillStyleOptions, InteractionEvent, Polygon, Texture } from "pixi.js";
import { isMobile } from "react-device-detect";
import { Point } from "../../../../helpers/Point";
import { viewportX, viewportY } from "../../../../helpers/ViewportCoordinates";
import { Furniture } from "../Furniture";
import { TransformLayer } from "./TransformLayer";
import { GetInformationFloor } from "../../actions/GetInformationFloor";
import { DeleteFurnitureAction } from "../../actions/DeleteFurnitureAction";
import { Tool } from "../../constants";
import { useStore } from "../../../../stores/EditorStore";
import { SaveAction } from "../../actions/SaveAction";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { setGondolasEx } from './../../../../ui/FurnitureControls/FurnitureAddPanel/FurnitureAddPanel';

export enum HandleType {
    Rotate,
    Vertical,
    HorizontalVertical,
    Horizontal,
    Move,
    Delete
}

export interface IHandleConfig {
    size?: number,
    color?: number,
    type: HandleType,
    target: Furniture,
    pos?: Point
}

export class Handle extends Graphics {

    private type: HandleType;
    private target: Furniture;
    private color: number = 0x000;
    private size: number = 10;
    private lastY:number;

    private active: boolean = false;
    private mouseStartPoint: Point;
    private targetStartPoint: Point;
    private mouseEndPoint: Point;
    private startRotaton: number;
    private startScale: Point;
    private targetStartCenterPoint: Point;
    localCoords: { x: number; y: number; };
    constructor(handleConfig: IHandleConfig) {
        super();
        this.interactive = true;
        if (handleConfig.color) {
            this.color = handleConfig.color;
        }

        if (handleConfig.size) {
            this.size = handleConfig.size;

        }

        this.mouseStartPoint = { x: 0, y: 0 };
        this.targetStartPoint = { x: 0, y: 0 };

        this.startScale = { x: 0, y: 0 };
        this.targetStartCenterPoint = { x: 0, y: 0 };
        this.localCoords = {x:0, y:0};
        this.mouseEndPoint = { x: 0, y: 0 };

        this.type = handleConfig.type;
        this.target = handleConfig.target;
        this.buttonMode = true;
        this.beginFill(this.color)
            .lineStyle(1, this.color)
        if (isMobile) {
            this.size = this.size * 3;
        }
        if (this.type == HandleType.Rotate) {
            let sizeMove = isMobile ? 20 : 12;
            let widthSize = isMobile ? 11 : 6;
            let pivotX = 10;
            let pivotY = 0;
            this.beginFill(0xFFFFFF,1).lineStyle({width: widthSize, color: 0x2196f3, alpha: 1 })
            .arc(sizeMove,0,sizeMove,2 * Math.PI, 3 * Math.PI / 2)
            .endFill();
            this.zIndex = 2;
            this.drawPolygon([
                (sizeMove*1.5), 0,
                (sizeMove*  2), -(sizeMove/2),
                (sizeMove*2.5), 0
            ]);
            this.pivot.set(pivotX,pivotY);
        } else if (this.type == HandleType.Delete) {
            let p = new Polygon();
            let scale = isMobile ? (this.size / 14) : 1.5;
            p.points = [
                (scale * -10) ,(scale * -8  ),
                (scale * -3 ) ,(scale * -8  ),
                (scale * -3 ) ,(scale * -10 ),
                (scale * 3  ) ,(scale * -10 ),
                (scale * 3  ) ,(scale * -8  ),
                (scale * 10 ) ,(scale * -8  ),
                (scale * 10 ) ,(scale * -6  ),
                (scale * 8  ) ,(scale * -6  ),
                (scale * 6  ) ,(scale * 10  ),
                (scale * -6 ) ,(scale * 10  ),
                (scale * -8 ) ,(scale * -6  ),
                (scale * -10) ,(scale * -6  ),
                (scale * -10) ,(scale * -8  )
            ];
            this.lineStyle({color: 0xb2292e}).beginFill(0xb2292e).drawPolygon(p).endFill();
        } else {
            let p = new Polygon();
            let scale = isMobile ? (this.size / 14) : 2.5;
            p.points = [
                (scale *   0), (scale *  12),
                (scale *  -6), (scale *   7),
                (scale *  -3), (scale *   7),
                (scale *  -3), (scale *   3),
                (scale *  -7), (scale *   3),
                (scale *  -7), (scale *   6),
                (scale * -12), (scale *   0),
                (scale *  -7), (scale *  -6),
                (scale *  -7), (scale *  -3),
                (scale *  -3), (scale *  -3),
                (scale *  -3), (scale *  -7),
                (scale *  -6), (scale *  -7),
                (scale *   0), (scale * -12),
                (scale *   6), (scale *  -7),
                (scale *   3), (scale *  -7),
                (scale *   3), (scale *  -3),
                (scale *   7), (scale *  -3),
                (scale *   7), (scale *  -6),
                (scale *  12), (scale *   0),
                (scale *   7), (scale *   6),
                (scale *   7), (scale *   3),
                (scale *   3), (scale *   3),
                (scale *   3), (scale *   7),
                (scale *   6), (scale *   7),
                (scale *   0), (scale *  12)
            ];
            this.lineStyle({color: this.color}).beginFill(this.color,1).drawPolygon(p).endFill();
            /* let sizeMove = this.size * 2;
            let zoomForWeb = 3;
            this.drawRect(0, 0, sizeMove, sizeMove).endFill();
            this.pivot.set(sizeMove/zoomForWeb,sizeMove/zoomForWeb); */
        }


        switch (this.type) {
            case HandleType.Move:
                // this.cursor = "move";
                break;
            case HandleType.Horizontal:
                this.cursor = "ew-resize";
                break;
            case HandleType.Vertical:
                this.cursor = "ns-resize";
                break;
            case HandleType.HorizontalVertical:
                this.cursor = "nwse-resize";
                break;
            case HandleType.Rotate:
                this.cursor = "wait";
                break;
            case HandleType.Delete:
                this.cursor = "help";
                break;
        }
        if (handleConfig.pos) {
            this.position.set(handleConfig.pos.x, handleConfig.pos.y);
        }

        this.on("pointerdown", this.onMouseDown)
        this.on("pointerup", this.onMouseUp)
        this.on("pointerupoutside", this.onMouseUp)
        this.on("pointermove", this.onMouseMove)
    }

    private onMouseDown(ev: InteractionEvent) {
        if (TransformLayer.dragging) {
            return;
        }

        if(this.type == HandleType.Delete){
            if(document.getElementById("count-"+this.target.data._id)){
                document.getElementById("count-"+this.target.data._id).innerHTML = (+document.getElementById("count-"+this.target.data._id).innerHTML - 1).toString();
            }            
            const action = new DeleteFurnitureAction(this.target.getId());
            action.execute();            
            TransformLayer.Instance.deselect();
            this.calculateQuote();
            return;
        }else if(this.type == HandleType.Rotate){
            this.target.switchOrientationHandle();
            return;
        }

        this.mouseStartPoint.x = ev.data.global.x
        this.mouseStartPoint.y = ev.data.global.y; // unde se afla target la mousedown
        this.targetStartPoint = this.target.getGlobalPosition();
        this.targetStartCenterPoint.x = this.targetStartPoint.x + this.target.width / 2;
        this.targetStartCenterPoint.y = this.targetStartPoint.y + this.target.height / 2;
        let localCoords = ev.data.getLocalPosition(this.target)
        this.startRotaton = this.target.rotation;
        this.startScale.x = this.target.scale.x;
        this.startScale.y = this.target.scale.y;
        TransformLayer.dragging = true;
        this.active = true;
        // this.target.setSmartPivot(0);
        ev.stopPropagation();
    }

    private onMouseUp(ev: InteractionEvent) {        
        if(this.type == HandleType.Rotate){
            switch(true){
                case (this.target.angle >= 0 && this.target.angle < 45):
                    this.target.angle = 0;
                    break;
                case (this.target.angle >= 45 && this.target.angle < 90):
                    this.target.angle = 90;
                    break;
                case (this.target.angle >= 90 && this.target.angle < 135):
                    this.target.angle = 90;
                    break;

                case (this.target.angle >= -45 && this.target.angle < 0):
                    this.target.angle = 0;
                    break;
                case (this.target.angle >= -90 && this.target.angle < -45):
                    this.target.angle = -90;
                    break;
                case (this.target.angle >= -135 && this.target.angle < -90):
                    this.target.angle = -90;
                    break;
                case (this.target.angle >= -180 && this.target.angle < -135):
                    this.target.angle = -180;
                    break;
                case (this.target.angle >= -225 && this.target.angle < -180):
                    this.target.angle = -180;
                    break;
                default:
                    this.target.angle = 0;
                    break;
            }
        }

        const node:any = new SaveAction();
        node.execute();

        TransformLayer.dragging = false;
        this.active = false;
        ev.stopPropagation();
    }

    private calculateQuote = () => {
        const node:any = new GetInformationFloor();
        node.execute();
        let total = node.receiver.totalPrices;

        if(document.getElementById("subtotal-1")){
            document.getElementById("subtotal-1").innerHTML = this.currencyFormat(total);
            document.getElementById("iva-1").innerHTML = this.currencyFormat((total/100)*16);
            document.getElementById("total-1").innerHTML = this.currencyFormat(total + ((total/100)*16));

            document.getElementById("subtotal-0").innerHTML = this.currencyFormat(total);
            document.getElementById("iva-0").innerHTML = this.currencyFormat((total/100)*16);
            document.getElementById("total-0").innerHTML = this.currencyFormat(total + ((total/100)*16));
        }   
    }

    private currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    private isCollidedForniture(r1:any,r2:any):boolean{
        if(
            r1[0] < r2[2] &&
            r1[2] > r2[0] &&
            r1[1] < r2[7] &&
            r1[7] > r2[1]
        ){
            return true;
        }
        return false;
    }

    private onMouseMove(ev: InteractionEvent) {
        if (!this.active || !TransformLayer.dragging) {
            return;
        }
        // start validated colladed
        const node:any = new GetInformationFloor();
        // dibujar solo sobre el área
        let walls = useStore.getState().walls;
        let vx = viewportX(ev.data.global.x);
        let vy = viewportY(ev.data.global.y);
        let firstPointX = vx-(this.target.width/2);
        let firstPointY = vy-(this.target.height/2);
        let secondPointX = vx+(this.target.width/2);
        let secondPointY = vy+(this.target.height/2);
        if(firstPointX <= walls[0].x || firstPointY <= walls[0].y || secondPointX >= walls[2].x || secondPointY >= walls[2].y){
            // left y top
            /* if(firstPointX <= walls[0].x && firstPointY >= walls[0].y && secondPointY <= walls[2].y){
                // left
                this.x = walls[0].x;
                this.y = viewportY(ev.data.global.y);
                this.target.position.x = this.x;
                this.target.position.y = this.y;
                this.update({x: this.x, y: this.y});
            }else if(firstPointY <= walls[0].y && firstPointX >= walls[0].x && secondPointX <= walls[2].x){
                // top
                this.y = walls[0].y;
                this.x = viewportX(ev.data.global.x);
                this.target.position.x = this.x;
                this.target.position.y = this.y;
                this.update({x: this.x, y: this.y});
            }else if(firstPointX < walls[0].x && firstPointY < walls[0].y){
                // top and left
                this.y = walls[0].y;
                this.x = walls[0].x;
                this.target.position.x = this.x;
                this.target.position.y = this.y;
                this.update({x: this.x, y: this.y});
            }else if(secondPointX >= walls[2].x && secondPointY <= walls[2].y && firstPointY >= walls[0].y){
                // right
                this.x = walls[2].x-this.target.width;
                this.y = viewportY(ev.data.global.y);
                this.target.position.x = this.x;
                this.target.position.y = this.y;
                this.update({x: this.x, y: this.y});
            }else if(secondPointX <= walls[2].x && firstPointX >= walls[0].x && secondPointY >= walls[2].y){
                // bottom
                this.y = walls[2].y-this.target.height;
                this.x = viewportX(ev.data.global.x);
                this.target.position.x = this.x;
                this.target.position.y = this.y;
                this.update({x: this.x, y: this.y});
            }else if(secondPointX > walls[2].x && secondPointY > walls[2].y){
                // bottom and right
                this.y = walls[2].y-this.target.height;
                this.x = walls[2].x-this.target.width;
                this.target.position.x = this.x;
                this.target.position.y = this.y;
                this.update({x: this.x, y: this.y});
            } */
            return;
        }
        let lenghtFurnitures:number = 0;
        let copyTarget;
        let collidedTarget;
        for(let a of node.receiver.furnitures){
            lenghtFurnitures++;
        }
        let isCollided:boolean = false;
        if(lenghtFurnitures>1){
            node.receiver.furnitures.forEach(f => {
                if(f.getId()==this.target.getId()){
                    copyTarget = f;
                }
            });
            node.receiver.furnitures.forEach(f => {
                if(f.getId()!=this.target.getId()){
                    let collided = this.isCollidedForniture(copyTarget.vertexData,f.vertexData);
                    if(collided){
                        isCollided = collided;
                        collidedTarget = f;
                    }
                }
            });
        }
        this.cursor = isCollided ? "not-allowed" : "move";
        if(isCollided){
            cleanNotifications();
            showNotification({
                title: '',
                message: 'No se permite encimar góndolas',
                styles: (theme) => ({
                    root: { '&::before': { backgroundColor: '#b2292e' } },
                    title: { color: '#b2292e' },
                    description: { color: '#b2292e !important' }
                }),
            });
        }else{
            cleanNotifications();
        }
        // end validated colladed
        // unde se afla mouse-ul acum
        this.mouseEndPoint.x = ev.data.global.x;
        this.mouseEndPoint.y = ev.data.global.y;
        // distanta de la obiect la punctul de start (unde a dat click utilizatorul)
        let startDistance = this.getDistance(this.mouseStartPoint, this.targetStartPoint)
        // distanta de la obiect la pozitia noua a mouse-ului
        let endDistance = this.getDistance(this.mouseEndPoint, this.targetStartPoint);
        // raportul dintre cele doua distante:  
        // raport > 1 -> se mareste obiectul
        // raport < 1 -> se micsoreaza obiectul
        let sizeFactor = endDistance / startDistance;
        let startCenterAngle = this.target.centerAngle;
        switch (this.type) {
            case HandleType.Rotate:                
                let relativeStart = {
                    x: this.mouseStartPoint.x - this.targetStartPoint.x,
                    y: this.mouseStartPoint.y - this.targetStartPoint.y
                }
                let relativeEnd = {
                    x: this.mouseEndPoint.x - this.targetStartPoint.x,
                    y: this.mouseEndPoint.y - this.targetStartPoint.y
                }

                let endAngle = Math.atan2(relativeEnd.y, relativeEnd.x);
                let startAngle = Math.atan2(relativeStart.y, relativeStart.x)
                let deltaAngle = endAngle - startAngle;
                this.target.rotation = this.startRotaton + deltaAngle;
                break;
            case HandleType.Horizontal:
                this.target.scale.x = this.startScale.x * sizeFactor;
                break;
            case HandleType.Vertical:
                this.target.scale.y = this.startScale.y * sizeFactor;
                break;
            case HandleType.HorizontalVertical:
                this.target.scale.x = this.startScale.x * sizeFactor;
                this.target.scale.y = this.startScale.y * sizeFactor;
                break;
            case HandleType.Move:
                // move delta: distanta intre click original si click in urma mutarii
                let delta;
                let y1 = this.mouseEndPoint.y - this.mouseStartPoint.y;
                let x1 = this.mouseEndPoint.x - this.mouseStartPoint.x;
                /* if(isCollided){
                    y1 = collidedTarget.vertexData[7] + ((copyTarget.vertexData[7]-copyTarget.vertexData[1])/2)- this.mouseStartPoint.y;
                    let worldX = viewportX(ev.data.global.x);
                    let worldY = viewportY(y1);
                    this.target.position.x = worldX;
                    this.target.position.y = worldY;
                    this.update({x: worldX, y: worldY});
                } */
                delta = {
                    x: x1,
                    y: y1
                }
                if (!this.target.xLocked) {
                    this.target.position.x = viewportX(this.targetStartPoint.x+ delta.x) 
                    this.target.position.y = viewportY(this.targetStartPoint.y + delta.y)
                } else {
                    let amount = (delta.x + delta.y)*0.8;
                    if (this.localCoords.x + amount >= this.target.parent.width - this.target.width) {
                        this.target.position.x = this.target.parent.width - this.target.width
                    } else if (this.localCoords.x + amount <=0 ){
                        this.target.position.x = 0;
                    } else {
                        this.target.position.x = this.localCoords.x + amount;

                    }
                    // this.target.position.x = viewportX(this.targetStartPoint.x) + delta.x;

                }
                break;
        }
    }

    private getDistance(src: Point, dest: Point) {
        return Math.sqrt(Math.pow(dest.x - src.x, 2) + Math.pow(dest.y - src.y, 2));
    }

    public setTarget(target: Furniture) {
        this.target = target;
    }

    /* sets scale and transform */
    public update(pos: Point) {
        switch (this.type) {
            case HandleType.Move:
                // this.cursor = "move";
                break;
            case HandleType.Horizontal:
                this.cursor = "ew-resize";
                break;
            case HandleType.Vertical:
                this.cursor = "ns-resize";
                break;
            case HandleType.HorizontalVertical:
                this.cursor = "nwse-resize";
                break;
            case HandleType.Rotate:
                this.cursor = "pointer";
                break;
            case HandleType.Delete:
                this.cursor = "pointer";
                break;
        }
        this.position.set(pos.x, pos.y)
    }
}