import { FloorPlan } from "../objects/FloorPlan";
import { Wall } from "../objects/Walls/Wall";
import { WallNode } from "../objects/Walls/WallNode";
import { Action } from "./Action";
import { AddWallManager } from "./AddWallManager";
import {useStore} from  "../../../stores/EditorStore"
import { snap } from "../../../helpers/ViewportCoordinates";
import { Point } from "../../../helpers/Point";
import { cleanNotifications, showNotification } from "@mantine/notifications";
// Add node to FloorPlan. if clicked on screen, just add it. otherwise, add it to the wall.
export class AddNodeAction implements Action {
    private wall:Wall;
    private coords:Point;
    private receiver:FloorPlan;   

    constructor(wall?:Wall, coords?:Point) {
        if (wall) {
            this.wall = wall;
        }
        if (coords) {
            this.coords = coords;
        }
        this.receiver = FloorPlan.Instance;
    }

    public execute() {
        // dibujar solo sobre el área
        let walls = useStore.getState().walls;
        let vx = this.coords.x;
        let vy = this.coords.y;
        if(vx < walls[0].x || vy < walls[0].y || vx > walls[2].x || vy > walls[2].y){
            cleanNotifications();
            showNotification({
                title: '',
                message: 'Solo se puede agregar paredes sobre el área definida previamente.',
                styles: (theme) => ({
                    root: { '&::before': { backgroundColor: '#b2292e' } },
                    title: { color: '#b2292e' },
                    description: { color: '#b2292e !important' }
                }),
            });
            return;
        }
        let node:WallNode;

        if (useStore.getState().snap == true) {
            this.coords.x = snap(this.coords.x)
            this.coords.y = snap(this.coords.y)
        }
        if (this.wall) {
            node = this.receiver.addNodeToWall(this.wall, this.coords);
            if (node == null) {
                return;
            }
        } else {
            if (!AddWallManager.Instance.checkStep(this.coords)) {
                return;
            }
            node = this.receiver.addNode(this.coords.x, this.coords.y)
        
        }
        AddWallManager.Instance.step(node);
        this.receiver.actions.push(this);
    }
}