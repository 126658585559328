import { ReactNode, useState } from 'react';
import { Group, Button, Text, Modal, Navbar, Drawer } from '@mantine/core';
import { useStore } from '../stores/EditorStore';
import { NavbarLink } from './NavbarLink';
import { Armchair, ArrowsMaximize, BorderLeft, Box, Click, DeviceFloppy, Edit, Eraser, GitFork, Help, LayoutAlignMiddle, Multiplier2x, RowInsertBottom, Vector, ZoomIn, GridDots } from 'tabler-icons-react';
import { Tool } from '../editor/editor/constants';
import { Image } from '@mantine/core';
import helpAddWall from '../res/add-wall.gif'
import helpDelete from '../res/delete2.gif'
import helpEditFurniture from '../res/edit-furniture2.gif'
import helpEditWall from '../res/edit-walls.gif'
import helpAddWindow from '../res/add-window.gif'
import helpAddDoor from '../res/add-door.gif'
import helpMeasure from '../res/measure-tool.gif'
import { isMobile } from 'react-device-detect';
import { FurnitureAddPanel } from './FurnitureControls/FurnitureAddPanel/FurnitureAddPanel';
import { useFurnitureStore } from '../stores/FurnitureStore';

interface IHelpBody {
  title: string,
  body: ReactNode
}

export function HelpDialog() {
  const { activeTool } = useStore();
  const [drawerOpened, setDrawerOpened] = useState(false);
  const {getCategories} = useFurnitureStore();
  const [active, setActive] = useState(-1);
  const { setTool, floor } = useStore();
  //const [ activeTooled, setActiveTooled] = useState(toolActive);
  let helpBody: IHelpBody[] = [];

  helpBody[Tool.View] = {
    title: "",
    body: <></>
  }
  helpBody[Tool.FirstEdit] = {
    title: "Plano",
    body: <>
      <Group>
        <p>¡Bienvenido al cotizador Tammex para góndolas! Con nuestra herramienta, podrás personalizar tus góndolas según tus necesidades específicas y obtener presupuestos precisos para tu negocio. Simplifica tu proceso de compra y optimiza tu espacio comercial con Tammex.</p>
      </Group>
      <Group>
        <p><Click />&nbsp;Haz clic derecho o arrastra con tu dedo para mover el plano.</p>
      </Group>
      <Group>
        <p><ZoomIn />&nbsp;Utiliza la rueda del mouse o haz zoom con tus dedos para acercarte o alejarte del plano.</p>
      </Group>
      <Group>
        <p>Ahora agreguemos góndolas:</p>
      </Group>
      <Group style={{marginTop: "-20px"}}>
        <p><RowInsertBottom />&nbsp;Abre el menú de góndola y selecciona el objeto que tu quieras.</p>
      </Group>
      <Group>
        <p><DeviceFloppy />&nbsp;Guarda y envía tu cotización.</p>
      </Group>
      <Group>
        <p><b>Recuerda que:</b><br/>
          <GridDots />&nbsp;Las cuadrículas tienen una medida de 50x50 cm.<br/>
          <ArrowsMaximize />&nbsp;Solo se puede mover los objetos sobre el área definida previamente.<br/>
          <Box />&nbsp;Solo se puede agregar los objetos sobre el área definida previamente.<br/>
          <BorderLeft />&nbsp;Solo se puede agregar paredes sobre el área definida previamente.
        </p>
      </Group>
    </>
  }
  helpBody[Tool.FurnitureAdd] = {
    title: "Agregar góndolas",
    body: <>
      <Group spacing="xs">
        <p><RowInsertBottom />&nbsp;Agrega una góndola desde el panel lateral derecho o haciendo clic en botón "agregar góndola" del menú izquierdo, para abrir el panel de las góndolas.</p>
      </Group>
    </>
  }
  helpBody[Tool.ShowSizes] = {
    title: "Medidas de las cuadrículas",
    body: <>
      <Group>
        <p><b>Recuerda que:</b><br/>
          <GridDots />&nbsp;Las cuadrículas tienen una medida de 50x50 cm.<br/>
        </p>
      </Group>
    </>
  }
  helpBody[Tool.Remove] = {
    title: "Eliminar objeto del plano",
    body: <>
      <Image src={helpDelete}></Image>
      <Group spacing="xs">
        <p><Eraser />&nbsp;Haz clic en el objeto para eliminarlo del plano.</p>
      </Group>
      <Group noWrap={true}>
        <p><Vector />&nbsp;Los nodos de la pared solo se pueden quitar si están desconectados</p>
      </Group>
    </>
  }
  helpBody[Tool.Edit] = {
    title: "Editar objeto del plano",
    body: <>
      <Image src={helpEditFurniture}></Image>
      <Group spacing="xs">
        <p>
          <Edit />&nbsp;Haz clic en el objeto para habilitar los controles de edición.<br/>
          - Eliminar desde el icono superior izquierdo del objeto.<br/>
          - Mover objeto desde el icono central del objeto.<br/>
          - Rotar 90 grados desde el icono inferior derecho del objeto.<br/>
          <b><ArrowsMaximize />&nbsp;Solo se puede mover los objetos sobre el área definida previamente.</b>
        </p>
      </Group>
      <Image src={helpEditWall}></Image>
      <Group noWrap={true}>
        <p>
          <Vector />&nbsp;Haz clic y arrastre los nodos de la pared para editar las paredes.<br />
          <b><ArrowsMaximize />&nbsp;Solo se puede mover los nodos de la pared sobre el área definida previamente.</b>
        </p>
      </Group>
    </>
  }
  helpBody[Tool.WallAdd] = {
    title: "Agregar pared",
    body: <>
      <Image src={helpAddWall}></Image>
      <Group noWrap={true}>
        <p><Click />&nbsp;Haz clic para agregar un nodo de la pared</p>
      </Group>
      <Group noWrap={true}>
        <p><Multiplier2x />&nbsp;Haz doble clic en el nodo de la pared para finalizar</p>
      </Group>
      <Group noWrap={true}>
        <p><GitFork />&nbsp;Haz clic en las paredes existentes para conectar</p>
      </Group>
      <Group>
        <p><b>Recuerda que:</b><br/>
          <BorderLeft />&nbsp;Solo se puede agregar paredes sobre el área definida previamente.
        </p>
      </Group>
    </>
  }

  helpBody[Tool.FurnitureAddWindow] = {
    title: "Add Window",
    body: <>
      <Image src={helpAddWindow}></Image>
      <Group noWrap={true}>
        <Click /> <p>Click on wall to add window</p>
      </Group>
    </>
  }
  helpBody[Tool.FurnitureAddDoor] = {
    title: "Add Door",
    body: <>
      <Image src={helpAddDoor}></Image>
      <Group noWrap={true}>
        <Click /> <p>Click on wall to add door</p>
      </Group>
      <Group noWrap={true}>
        <LayoutAlignMiddle /> <p>Middle click to change door orientation</p>
      </Group>
    </>
  }
  helpBody[Tool.Measure] = {
    title: "Measure tool",
    body: <>
      <Image src={helpMeasure}></Image>
      <Group noWrap={true}>
        <Click /> <p>Click and drag to measure distances</p>
      </Group>
    </>
  }
  let modal = (<Modal
    title={helpBody[activeTool].title}
    opened={useStore(state=>state.showHelper)}
    withCloseButton
    onClose={()=>{
      if(helpBody[activeTool].title == "Plano"){
        useStore.setState({ showToolbar: true });
        setTool(Tool.ShowSizes);
      }else{
        useStore.setState({ showHelper: false });
      }
    }}
    size="lg"
    radius="md">
    <Text size="sm" style={{ marginBottom: 10 }} weight={500}>
      {helpBody[activeTool].body}
    </Text>

    <div style={{ display: helpBody[activeTool].title == "Plano" ? 'flex' : 'none' }} className='container-center w-100'>
      <Button onClick={() =>{
        useStore.setState({ showToolbar: true });
        setTool(Tool.ShowSizes);
      }} style={{ backgroundColor: "#b2292e", color: "white" }}>Continuar</Button>
    </div>

    <div style={{ display: helpBody[activeTool].title != "Plano" ? 'flex' : 'none' }} className='container-center w-100'>
      <Button onClick={() =>{
        useStore.setState({ showHelper: false });
      }} style={{ backgroundColor: "#b2292e", color: "white" }}>Continuar</Button>
    </div>



  </Modal>);
  if(isMobile){
    return(
      <>
        {modal}
        <Navbar style={{height:'0px'}}>
          <Navbar.Section>
            <Group>
              <Drawer
                opened={drawerOpened}
                position='right'
                onClose={() => {getCategories(); setDrawerOpened(false)}}
                title="Agregar góndola"
                padding="xl"
                size="lg"
                overlayOpacity={0}>
                <FurnitureAddPanel key={'mobil-panel'}  />
              </Drawer>
            </Group>
          </Navbar.Section>
        </Navbar>
      </>
    );
  }
  return (
    <>
      <Group position="center">
        <NavbarLink onClick={()=>{useStore.setState({ showHelper: true });}} icon={Help} label="Ayuda" />
      </Group>
      
      {modal}
      <Navbar style={{height:'0px'}}>
        <Navbar.Section>
          <Group>
            <Drawer
              opened={drawerOpened}
              position='right'
              onClose={() => {getCategories(); setDrawerOpened(false)}}
              title="Agregar góndola"
              padding="xl"
              size={400}
              overlayOpacity={0}>
              <FurnitureAddPanel key={'pc-panel'} />
            </Drawer>
          </Group>
        </Navbar.Section>
      </Navbar>
    </>
  );
}