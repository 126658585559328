import { cleanNotifications, showNotification } from "@mantine/notifications";
import { FloorPlan } from "../objects/FloorPlan";
import { Action } from "./Action";
import { SaveAction } from "./SaveAction";

// Action for removing furniture piece from FloorPlan.
export class DeleteFurnitureAction implements Action {
    private id:number;
    private receiver:FloorPlan;

    constructor(id:number) {
        this.id = id;
        this.receiver = FloorPlan.Instance;

        cleanNotifications();
        showNotification({
            title: '',
            message: `Se eliminó el producto correctamente de la cotización.`,
            styles: (theme) => ({
                root: { '&::before': { backgroundColor: '#4caf50' } },
                title: { color: '#4caf50' },
                description: { color: '#4caf50 !important'  }
            }),
        });  
    }

    public execute() {
        this.receiver.actions.push(this);
        this.receiver.removeFurniture(this.id);
        const node:any = new SaveAction();
        node.execute();
    }
}

