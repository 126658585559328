import { FloorPlan } from "../objects/FloorPlan";
import { Action } from "./Action";

export class GetInformationFloor implements Action {
    private receiver:FloorPlan;

    constructor() {
        this.receiver = FloorPlan.Instance;
    }

    public execute() {
        this.receiver.actions.push(this);
        this.receiver.getTotalFurnitures();
    }
}

