import create from "zustand";
import { getProducts } from "../api/api-client";
import { useState } from "react";
import { useStore } from "./EditorStore";

export interface Category {
    _id: string,
    name: string,
    visible: boolean
}

export interface FurnitureData {
    _id?: string,
    name?: string,
    count?:number,
    width: number,
    height: number,
    imagePath: string,
    imagePreview: string,
    category?: string,
    zIndex?: number,
    price?: number,
    isGondola?: boolean
}

export interface FurnitureStore {
    categories: Category[],
    currentFurnitureData: FurnitureData[]
    getCategories: () => void,
    getCurrentFurnitureData: (categoryId:string) => void
}

export const useFurnitureStore = create<FurnitureStore>(set => ({
    categories: [
        {
            "_id": "1",
            "name": "Principal",
            "visible": true
        }        
    ],
    currentFurnitureData: [],
    getCategories: async () => {
    },
    getCurrentFurnitureData: async (categoryId:string) => {
        let address = useStore.getState().address;
        if(!address.zoneId) return;
        
        let res = await getProducts(address.zoneId);
        let arr = [];

        await res.data.forEach(p => {
            let data = {
                "_id": p.productId,
                "name": p.product,
                "width": p.width,
                "height": p.height,
                "imagePath": p.urlImage,
                "imagePreview": p.urlImagePreview,
                "category": "1",
                "price": p.price,
                "isGondola": Boolean(p.isGondola)
            };
            arr = [...arr, data];
        });

        set(() => ({
            currentFurnitureData: arr
        }));       
    }
}))